<template>
  <div
    :class="`brand-icons-library-wrapper ${
      error.isError ? 'errorOrEmpty' : ''
    } ${isGenerate ? 'generate' : ''}`"
  >
    <v-container v-if="isLoading">
      <v-text-field
        v-if="!error.isError && isSearchBarEnabled"
        v-model="searchKey"
        append-icon="mdi-magnify"
        single-line
        hide-details
        :placeholder="$t('generate.searchIcons')"
        class="brand-icons-search-input"
        autocomplete="off"
      />
      <IconsPlaceHolder
        v-if="isLoading"
        :placeholderHeight="`${
          mainSlideHeight ? `${mainSlideHeight - 75}px` : ''
        }`"
      />
    </v-container>
    <v-container
      v-else
      :style="{
        height: `${mainSlideHeight ? `${mainSlideHeight}px` : ''}`,
      }"
    >
      <v-text-field
        v-if="!error.isError && isSearchBarEnabled"
        v-model="searchKey"
        append-icon="mdi-magnify"
        single-line
        hide-details
        :placeholder="$t('generate.searchIcons')"
        class="brand-icons-search-input"
        autocomplete="off"
      />
      <div
        class="error-wrapper"
        v-if="error.isError || error.isEmpty"
        :style="{
          height: `${
            mainSlideHeight
              ? `${mainSlideHeight - (isSearchBarEnabled ? 75 : 0)}px`
              : ''
          }`,
        }"
      >
        <EmptyState
          :img-url="error.imgUrl"
          :is-btn-visible="error.isShowButton"
          :btnClass="error.emptyStateBtnClass"
          :buttonName="error.buttonName"
          :isBtnOutlined="error.btnOutlined"
          :handleCTA="error.handleCTA"
        >
          <template v-slot:description>
            <div>
              <p class="error-description">{{ $t(error.message) }}</p>
              <p class="error-description">
                {{ $t(error.additionalDescription) }}
              </p>
            </div>
          </template>
        </EmptyState>
      </div>
      <div
        v-else
        :class="`icons ${isSearchBarEnabled ? 'search' : 'no-search'}`"
        @scroll="onScroll"
        ref="companyIcons"
        :style="{
          height: `${
            mainSlideHeight
              ? `${mainSlideHeight - (isSearchBarEnabled ? 75 : 0)}px`
              : ''
          }`,
        }"
      >
        <template>
          <div class="iconsWrapper">
            <div
              :class="`iconContainer ${
                icon.id === selectedIcon ? 'selected-icon' : ''
              } library-icon`"
              :style="{
                height: `${Number(
                  Number(mainSlideHeight / 4).toFixed(3),
                )}px !important`,
              }"
              v-for="(icon, ind) in icons"
              :key="ind"
              @click="onIconClick(icon)"
            >
              <img
                :key="icon.id"
                :src="icon.url"
                lazy-src="/assets/img/slides/placeholder-slide.svg"
                class="grey lighten-2"
                contain
              />
            </div>
          </div>
          <clip-loader
            v-if="moreIconsLoading && !allResultsLoaded"
            :color="`#21a7e0`"
            :width="'24'"
            :height="'24'"
            :size="'24px'"
            class="cliploader"
          />
        </template>
      </div>
    </v-container>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import EmptyState from '../../common/EmptyState.vue';
import IconsPlaceHolder from './IconsPlaceHolder.vue';
import EventBus from '../../common/event-bus';
import { miscConfig } from '../../../runtimeConfig';
import { getLoggedInUserToken } from '../../../utils/api';
import {
  FLAT_ICON_SELECTED,
  BRAND_ICON_SELECTED,
  ICON_UPLOADING,
  ICON_UPLOAD_FAILED,
  LOCAL_ICON_SELECTED,
} from './replaceIconConstants';
import { TypeSenseServer } from '../../../pages/components/TypeSenseInit';

import {
  handleDebounce,
  handleDebounceForOnScroll,
} from './slideIconReplacementUtils';
import {
  logSearchQuery,
  getSearchBrandIconsSolr,
} from '../../../utils/api-helper';
// import {
//   TD_REPLACE_ICON_SOURCE_BRAND,
//   TD_PREZNAME,
//   TD_TEMPLATE,
// } from '../../common/Analytics/MatomoTrackingDataHelper';
// import { trackComplyEvents } from '../../common/Analytics/ComplyEvents';

const SOMETHING_WRONG = 'prezentationGallery.havingTroubleAccessingBrandIcons';
const NO_SEARCH_RESULTS = 'prezentationGallery.noResultText';
const ERROR_ICON_URL = '/assets/img/image-fetching-error.svg';
const NO_RESULTS_ICON_URL = '/assets/img/no-files-available.svg';
const NO_RESULTS_ICON_URL_TRY_NOW = '/assets/img/searching-for-image.svg';
const REPLACE_ICON_BRAND_SEARCHBAR = 'replace_icon_brand_library_searchbar';

export default {
  name: 'BrandIcons',
  props: {
    selectedIconData: {
      type: Object,
      required: false,
    },
    onTryFlatIconsSearch: {
      type: Function,
      default: () => {},
    },
    isFlatIconsLibraryEnabled: {
      type: Boolean,
      default: false,
    },
    slideData: {
      type: Object,
      required: false,
    },
    isGenerate: {
      type: Boolean,
      default: false,
    },
    defaultIcons: {
      type: Array,
      default: () => [],
      required: false,
    },
    origin: {
      type: String,
      default: null,
    },
  },
  components: {
    EmptyState,
    IconsPlaceHolder,
    ClipLoader,
  },
  watch: {
    searchKey(key) {
      this.onSearch(key);
    },
  },
  async beforeMount() {
    if (!this.selectedIconData) {
      return;
    }
    if (this.defaultIcons && this.defaultIcons.length > 1) {
      const defIcons = this.defaultIcons.slice(1);
      const defIconsWithThumbnails =
        await this.getDefaultIconsFromAwsAssetDistribution(defIcons);
      this.defIcons = [...defIconsWithThumbnails, ...this.icons];
    }
    if (this.getDatafromSolr)
      await this.loadBrandIconsFromSolr({
        query: this.searchKey || this.defaultSearchKey,
        skip: this.skip,
        templates: this.currentTheme?.code || '',
      });
    else
      await this.loadBrandIcons({
        query: this.searchKey || this.defaultSearchKey,
        page: this.page,
      });
    this.icons = [...this.defIcons, ...this.icons];
  },
  mounted() {
    if (window.ResizeObserver && !this.isGenerate) {
      const mainSlideSizeObserver = new ResizeObserver((data) => {
        this.mainSlideHeight = data && data[0] && data[0].contentRect.height;
      });
      mainSlideSizeObserver.observe(
        document.getElementById('replace-main-icon'),
      );
    }
  },
  created() {
    EventBus.$on(LOCAL_ICON_SELECTED, this.emptySelectedIcon);
    EventBus.$on(FLAT_ICON_SELECTED, this.emptySelectedIcon);
  },
  beforeDestroy() {
    EventBus.$off(LOCAL_ICON_SELECTED, this.emptySelectedIcon);
    EventBus.$off(FLAT_ICON_SELECTED, this.emptySelectedIcon);
  },
  data() {
    return {
      icons: [],
      searchKey: '',
      selectedIcon: '',
      isLoading: false,
      error: {},
      page: 1,
      skip: 0,
      limit: 30,
      mainSlideHeight: 0,
      defaultSearchKey: '*',
      typeSenseSearchData: {},
      solrSearchData: {},
      moreIconsLoading: false,
      getDatafromSolr: true,
      defIcons: [],
    };
  },
  computed: {
    ...mapState('users', ['currentUser', 'currentTheme']),
    ...mapState('comply', ['fileDetails', 'selectedTemplate']),
    isSearchBarEnabled() {
      const searchBar = this.currentUser?.features?.find(
        (f) => f.feature_name === REPLACE_ICON_BRAND_SEARCHBAR,
      );
      return searchBar?.enabled;
    },
    allResultsLoaded() {
      if (this.getDatafromSolr)
        return this.icons?.length >= this.solrSearchData?.hits;
      return this.icons?.length === this.typeSenseSearchData?.found;
    },
  },
  methods: {
    emptySelectedIcon() {
      this.selectedIcon = '';
    },
    async loadBrandIconsFromSolr({ query, skip, templates }) {
      try {
        this.isLoading = true;
        const searchParams = {
          query,
          skip,
          templates,
        };
        const searchResults = await this.searchBrandIconsFromSolr(searchParams);
        this.icons = await this.getBrandIconsFromAwsAssetDistribution(
          searchResults,
        );
        this.isLoading = false;
        this.checkDataAndSetState();
        if (query && query !== '*') {
          const logPayload = {
            type: 'Icon Replacement (Company)',
            searchTerm: query,
            searchTermResult: false,
          };
          if (this.icons.length > 0) {
            logPayload.searchTermResult = true;
          }
          logSearchQuery(logPayload)
            .then()
            .catch((err) => console.log(err));
        }
      } catch (err) {
        console.log(err);
        this.isLoading = false;
        this.setErrorObject({
          isError: true,
          message: SOMETHING_WRONG,
          imgUrl: ERROR_ICON_URL,
        });
      }
    },
    async loadBrandIcons({ query, page }) {
      try {
        this.isLoading = true;
        const searchParams = {
          query,
          page,
        };
        const searchResults = await this.searchBrandIcons(searchParams);
        this.icons = await this.getBrandIconsFromAwsAssetDistribution(
          searchResults,
        );
        this.isLoading = false;
        this.checkDataAndSetState();
        if (query && query !== '*') {
          const logPayload = {
            type: 'Icon Replacement (Company)',
            searchTerm: query,
            searchTermResult: false,
          };
          if (this.icons.length > 0) {
            logPayload.searchTermResult = true;
          }
          logSearchQuery(logPayload)
            .then()
            .catch((err) => console.log(err));
        }
      } catch (e) {
        this.isLoading = false;
        console.log(e);
        this.setErrorObject({
          isError: true,
          message: SOMETHING_WRONG,
          imgUrl: ERROR_ICON_URL,
        });
      }
    },

    async getDefaultIconsFromAwsAssetDistribution(files) {
      const token = await getLoggedInUserToken();
      return await files.map((file) => ({
        ...file,
        id: file.s3_path,
        url: `${miscConfig.aws_assets_distribution}/${file.s3_path}?accesskey=${token}`,
      }));
    },

    async getBrandIconsFromAwsAssetDistribution(files) {
      const token = await getLoggedInUserToken();
      return await files.map((file) => ({
        id: file.filepath,
        url: `${miscConfig.aws_assets_distribution}/private/${file.filepath}?accesskey=${token}`,
      }));
    },
    checkDataAndSetState() {
      if (!this.icons.length) {
        // this.$store.commit('comply/SET_HAS_BRAND_IMAGES', false);
        let erroObject = {
          isEmpty: true,
          message: NO_SEARCH_RESULTS,
          imgUrl: NO_RESULTS_ICON_URL,
        };
        if (this.isFlatIconsLibraryEnabled) {
          erroObject = {
            ...erroObject,
            emptyStateBtnClass: 'adobe-try-now',
            btnOutlined: true,
            buttonName: 'slides.tryNow',
            isShowButton: true,
            handleCTA: this.onTryFlatIconsSearch.bind(this, this.searchKey),
            imgUrl: NO_RESULTS_ICON_URL_TRY_NOW,
            message: `Looking for ${
              this.searchKey || this.slideData?.title
            } icons ?`,
            additionalDescription:
              'prezentationGallery.trySearchingInOurLibrary',
          };
        }
        this.setErrorObject(erroObject);
      } else {
        // this.$store.commit('comply/SET_HAS_BRAND_IMAGES', true);
        this.setErrorObject({
          isEmpty: false,
          message: '',
          imgUrl: '',
        });
      }
    },
    setErrorObject(errorObj) {
      this.error = errorObj;
    },
    async onIconClick(icon) {
      this.selectedIcon = icon.id;
      EventBus.$emit(ICON_UPLOADING);
      try {
        this.$emit('iconSelected', {
          ...icon,
          // origin: TD_REPLACE_ICON_SOURCE_BRAND,
          origin: 'brand-icons',
          s3_path:
            icon.type && icon.type === 'default' ? icon.s3_path : `/${icon.id}`,
          url: icon.url,
          source: 's3',
        });
        EventBus.$emit(BRAND_ICON_SELECTED);
      } catch (e) {
        console.log('uploadBrandIconErr error: ', e);
        EventBus.$emit(ICON_UPLOAD_FAILED);
      }
      // if (this.origin && this.origin === 'comply') {
      //   const otherData = {
      //     [TD_PREZNAME]: this.fileDetails.name,
      //     [TD_TEMPLATE]: this.selectedTemplate,
      //   };
      //   trackComplyEvents.complianceCheckerImageChanged(
      //     this.currentUser,
      //     otherData,
      //   );
      // }
    },
    async searchBrandIcons({ query, page }) {
      const searchParameters = {
        q: query,
        query_by: 'tags',
        page,
        per_page: this.limit,
      };
      const tsClient = await TypeSenseServer.getClient();
      return tsClient
        .collections('brand_icons')
        .documents()
        .search(searchParameters)
        .then((response) => {
          const { found, hits, page: currentSearchPage } = response;
          this.typeSenseSearchData = {
            found,
            currentSearchPage,
          };
          if (hits?.length) {
            return hits.map((icon) => ({
              filepath: icon?.document?.filepath,
            }));
          }
          return [];
        });
    },
    async searchBrandIconsFromSolr({ query, skip, templates }) {
      const resp = await getSearchBrandIconsSolr({ query, skip, templates });
      const hits = resp?.data?.hits;
      this.solrSearchData = {
        hits,
        currentLoaded: skip + 12,
      };
      if (resp?.data?.docs?.length) {
        return resp?.data?.docs.map((icon) => ({
          filepath: icon?.filepath,
        }));
      }
      return [];
    },
    async doSearch(searchTerm) {
      this.page = 1;
      await this.loadBrandIcons({ query: searchTerm, page: this.page });
      if (searchTerm) {
        this.$emit('iconSearch', {
          searchTerm,
          // source: TD_REPLACE_ICON_SOURCE_BRAND,
          source: '',
          noResultsFoundValue: this.icons.length ? 0 : 1,
        });
      } else if (this.defIcons.length > 0) {
        this.icons = [...this.defIcons, ...this.icons];
      }
      if (this.$refs.companyIcons) {
        this.$refs.companyIcons.scrollTop = 0;
      }
    },
    async doSearchFromSolar(searchTerm) {
      this.skip = 0;
      await this.loadBrandIconsFromSolr({
        query: searchTerm,
        skip: this.skip,
        templates: this.currentTheme?.code || '',
      });
      if (searchTerm) {
        this.$emit('iconSearch', {
          searchTerm,
          // source: TD_REPLACE_ICON_SOURCE_BRAND,
          source: '',
          noResultsFoundValue: this.icons.length ? 0 : 1,
        });
      } else if (this.defIcons.length > 0) {
        this.icons = [...this.defIcons, ...this.icons];
      }
      if (this.$refs.companyIcons) {
        this.$refs.companyIcons.scrollTop = 0;
      }
    },
    onSearch(searchTerm) {
      if (this.getDatafromSolr)
        handleDebounce(() => this.doSearchFromSolar(searchTerm.trim()));
      else handleDebounce(() => this.doSearch(searchTerm.trim()));
    },
    onScroll(elementObject) {
      this.moreIconsLoading = true;
      if (this.getDatafromSolr)
        handleDebounce(() => this.actOnScrollSolr(elementObject));
      else handleDebounceForOnScroll(() => this.actOnScroll(elementObject));
    },
    async actOnScrollSolr({
      target: { scrollTop, clientHeight, scrollHeight },
    }) {
      if (scrollTop + clientHeight + 200 >= scrollHeight) {
        if (!this.error.isError && !this.allResultsLoaded) {
          try {
            this.skip += 12;
            const searchResults = await this.searchBrandIconsFromSolr({
              query: this.searchKey.trim() || this.defaultSearchKey,
              skip: this.skip,
              templates: this.currentTheme?.code || '',
            });
            const newIcons = await this.getBrandIconsFromAwsAssetDistribution(
              searchResults,
            );
            this.icons = [...this.icons, ...newIcons];
            this.moreIconsLoading = false;
          } catch (e) {
            console.log(e);
            this.moreIconsLoading = false;
            this.setErrorObject({
              isError: true,
              message: SOMETHING_WRONG,
              imgUrl: ERROR_ICON_URL,
            });
          }
        } else {
          this.moreIconsLoading = false;
        }
      }
      this.moreIconsLoading = false;
    },

    async actOnScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 200 >= scrollHeight) {
        if (!this.error.isError && !this.allResultsLoaded) {
          try {
            this.page += 1;
            const searchResults = await this.searchBrandIcons({
              query: this.searchKey.trim() || this.defaultSearchKey,
              page: this.page,
            });
            const newIcons = await this.getBrandIconsFromAwsAssetDistribution(
              searchResults,
            );
            this.icons = [...this.icons, ...newIcons];
            this.moreIconsLoading = false;
          } catch (e) {
            console.log(e);
            this.moreIconsLoading = false;
            this.setErrorObject({
              isError: true,
              message: SOMETHING_WRONG,
              imgUrl: ERROR_ICON_URL,
            });
          }
        } else {
          this.moreIconsLoading = false;
        }
      }
      this.moreIconsLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.brand-icons-library-wrapper {
  // height: 456px;

  ::v-deep .adobe-try-now {
    padding-bottom: 0px;
  }
  .brand-icons-search-input {
    background-color: #fff;
    border-radius: 24px;
    box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
    height: 40px;
    margin-bottom: 14px;
    font-size: 14px;
    color: #464a4c;
    margin-top: unset;
    padding: 0.27rem 0.7rem;
    z-index: 3;
    ::v-deep .v-input__slot::before {
      border: unset;
    }
    ::v-deep .v-text-field__slot input::placeholder {
      color: #757575;
    }
  }

  .empty-state__container {
    margin: unset;
    padding: 3rem;
  }

  .error-description {
    color: #000;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    /** trying to remove the padding as shown in the below class -> .no-prez-description but not working as expected hence negative margin here*/
    margin-top: -10px;
    text-align: center;
  }
  .no-prez-description {
    padding-top: 0px;
  }

  .icons,
  .error-wrapper {
    // max-height: 392px;
    overflow-y: auto;
    // padding-right: 12px;
  }

  .icons {
    .iconsWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      padding: 4px;
      .iconContainer {
        background: #ffffff !important;
        background-color: #ffffff !important;
        box-shadow: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(33.333% - 16px);
        box-sizing: border-box;
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
          rgba(90, 125, 188, 0.05) 0px 0.25em 1em !important;
        border-radius: 8px;
        cursor: pointer;
        img {
          width: 50% !important;
          height: 50% !important;
          background: #ffffff !important;
          background-color: #ffffff !important;
          box-shadow: none !important;
        }
        &:hover {
          background: #ffffff !important;
          background-color: #ffffff !important;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
        }
      }
    }
  }

  .icons.no-search {
    // max-height: 464px;
  }

  .library-icon {
    &:hover {
      box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
        0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%) !important;
      cursor: pointer;
    }
    background-color: #e0e0e0 !important;
    border-color: #e0e0e0 !important;
    border-radius: 8px;
    box-shadow: $item-box-shadow;
    margin-bottom: 10px;
  }

  .selected-icon {
    border: 3px solid #21a7e0 !important;
    box-shadow: 2px 0px 4px rgba(216, 216, 216, 0.5),
      0px 2px 4px rgba(216, 216, 216, 0.5);
  }
}

.generate {
  // height: auto;
  .icons,
  .error-wrapper {
    overflow-y: auto;
    // max-height: calc(100vh - 390px);
  }
  .icons {
    .iconsWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      padding: 4px;
      .iconContainer {
        background: #ffffff !important;
        background-color: #ffffff !important;
        box-shadow: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(33.333% - 16px);
        box-sizing: border-box;
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
          rgba(90, 125, 188, 0.05) 0px 0.25em 1em !important;
        border-radius: 8px;
        cursor: pointer;
        img {
          width: 50% !important;
          height: 50% !important;
          background: #ffffff !important;
          background-color: #ffffff !important;
          box-shadow: none !important;
        }
        &:hover {
          background: #ffffff !important;
          background-color: #ffffff !important;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
        }
      }
    }
  }
}
</style>
