/* eslint-disable class-methods-use-this */
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { getFileURL } from '@/utils/calyrex';
import {
  cancelGSRequest,
  checkBulkStatus,
  checkGSStatus,
  checkTaskStatus,
  generateSlideRequest,
  getRecommendedSlides,
  getSiblings,
  getSlideDataForDetailView,
} from '../../../utils/api-helper';
import { SummaryFormat, SynthesizeOperation } from './SynthesizeUtills';

import API from '../../../utils/api';

export class SynthesizeService {
  jobFormatMap = {};

  jobIds = [];

  intervals = [];

  constructor() {
    console.log('SynthesizeService initiated');
  }

  async start(context, bulk = false, source = undefined) {
    const time = new Date().getTime();

    const id = uuidv4();
    const payload = {
      callback_id: id,
      report_id: id,
      workflow_name: 'synthesize',
      workflow_context: context,
      source,
    };

    const jobResponse = await generateSlideRequest(payload);
    console.debug('Job Started:', jobResponse);
    this.jobIds.push(id);
    this.jobFormatMap[id] =
      context.summary_format || SummaryFormat.SimplePointers;

    if (!bulk) {
      const jobResult = await checkGSStatus(id, (interval) => {
        this.intervals.push(interval);
      });

      const idx = this.jobIds.indexOf(id);
      if (idx >= 0) {
        this.jobIds.splice(idx, 1);
        delete this.jobFormatMap[id];
      }

      const timeTaken = (new Date().getTime() - time) / 1000;
      console.debug(`Job Completed in ${timeTaken}:`, { jobResult, payload });

      return {
        slideOutput: jobResult.outputs.SlideAggregator.output,
        time: moment(jobResult.execution_end, 'DD/MM/YY HH:mm:ss:SS').diff(
          moment(jobResult.execution_start, 'DD/MM/YY HH:mm:ss:SS'),
          'seconds',
        ),
        jobId: id,
      };
    }
    return {};
  }

  async checkSlidesStatus(callback) {
    return new Promise((resolve, reject) => {
      let pollInterval;
      let responseArrived = false;
      const getStatus = async () => {
        try {
          const resp = await checkBulkStatus(this.jobIds);
          responseArrived = true;
          const slideResp = [];
          const ids = [...this.jobIds];
          ids.forEach((id) => {
            if (
              resp[id] &&
              (resp[id].workflow_status === 'success' ||
                resp[id].workflow_status === 'failed')
            ) {
              slideResp.push({
                format: this.jobFormatMap[id] || SummaryFormat.SimplePointers,
                id,
                resp:
                  resp[id].workflow_status === 'failed'
                    ? {}
                    : resp[id].outputs.SlideAggregator.output,
                error: resp[id].workflow_status === 'failed',
              });
              this.jobIds.splice(this.jobIds.indexOf(id), 1);
              delete this.jobFormatMap[id];
            }
          });
          if (this.jobIds.length === 0) {
            resolve(resp);
            clearInterval(pollInterval);
          }
          callback(slideResp);
        } catch (err) {
          console.log(err);
          responseArrived = true;
          clearInterval(pollInterval);
          reject(err);
        }
      };
      getStatus();
      pollInterval = setInterval(() => {
        if (responseArrived) {
          getStatus();
          responseArrived = false;
        }
      }, 2000);
      this.intervals.push(pollInterval);
    });
  }

  async startUpload(context, task) {
    const time = new Date().getTime();

    const id = uuidv4();
    const payload = {
      callback_id: id,
      report_id: id,
      workflow_name: 'synthesize-upload',
      workflow_context: context,
    };

    const jobResponse = await generateSlideRequest(payload);
    console.debug('Job Started:', jobResponse);
    this.jobIds.push(id);

    const jobResult = await checkTaskStatus(
      id,
      (interval) => {
        this.intervals.push(interval);
      },
      task,
    );

    const timeTaken = (new Date().getTime() - time) / 1000;
    console.debug(`Job Completed in ${timeTaken}:`, { jobResult, payload });

    return { output: jobResult, jobId: id };
  }

  async fetchSlideMetadata(output, currentUser, changeReq) {
    const format = output.format || output.suggested_summary_format;
    const execSummary = {
      slide_path: output.exec_pptx,
      nodeCount: output.exec_metadata.node,
    };
    const landscape = await getFileURL(
      currentUser.user.cognitoID,
      output.exec_thumbnail,
      currentUser.userIp,
      'IOCUpload',
    );
    execSummary.thumbnail = output.exec_thumbnail;
    execSummary.landscape = landscape;
    execSummary.meta = output.exec_metadata;
    const slideId =
      changeReq && changeReq.type === SynthesizeOperation.LayoutChange
        ? changeReq.slide.unique_id
        : output.exec_metadata.id;
    const slideDetails = await getSlideDataForDetailView(slideId);
    execSummary.slideDetails = slideDetails.data;
    const uniqueId =
      execSummary.slideDetails.parent &&
      execSummary.slideDetails.parent.unique_id
        ? execSummary.slideDetails.parent.unique_id
        : slideId;
    const sid =
      execSummary.slideDetails.parent && execSummary.slideDetails.parent.sid
        ? execSummary.slideDetails.parent.sid
        : changeReq.slide.parent.sid;
    const siblings = await getSiblings(uniqueId, sid, currentUser);
    execSummary.siblings = siblings.filter((node) => {
      if (
        format.includes(SummaryFormat.SimplePointers) &&
        [2, 3, 4, 5].includes(parseInt(node.asset.prefs.node, 10))
      ) {
        return true;
      }
      if (node.asset.prefs.node > 0 && node.asset.prefs.node < 4) {
        return true;
      }
      return false;
    });
    if (changeReq?.type !== SynthesizeOperation.LayoutChange) {
      output.recommended_slide_ids.splice(
        output.recommended_slide_ids.indexOf(slideId),
        1,
      );
      const reccos = await getRecommendedSlides(
        [slideId, ...output.recommended_slide_ids],
        currentUser,
      );
      execSummary.relatedSlides = reccos;
    }
    return execSummary;
  }

  async cancel() {
    this.intervals.forEach((interval) => clearInterval(interval));
    this.intervals = [];

    await Promise.all(
      this.jobIds.map(async (jobId) => {
        await cancelGSRequest(jobId);
      }),
    ).then(() => {
      this.jobIds = [];
      this.jobFormatMap = {};
    });
  }

  async createStats(data) {
    const uuid = uuidv4();

    const payload = {
      uuid,
      ...data,
    };

    await API.post('/api3/synthesis/add', payload);
    console.debug('Synthesis Log: Create Successfull');

    return uuid;
  }

  async updateStats(uuid, data) {
    const payload = {
      uuid,
      ...data,
    };

    await API.put('/api3/synthesis/update', payload);
    console.debug('Synthesis Log: Update Successfull');
  }
}
