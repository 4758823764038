<template lang="">
  <div class="slideReplaceVisualsContainer">
    <div class="compHeader">
      {{ $t('slideDetails.replaceVisualsHeader') }}
    </div>
    <!-- <div class="compSubHeader">
      {{ $t('slideDetails.compSubHeaderAstridTM') }}
    </div> -->

    <div class="switchButtonsContainer">
      <v-btn
        v-if="showReplaceImage"
        :class="`imagesBtn ${
          getReplacementViewSelected === 'imagesView' ? 'btnActive' : ''
        } ${disableImagesBtn || getIsLoadingInRepImage ? 'btnDisabled' : ''}`"
        :ripple="false"
        @click="selectView('imagesView')"
        :disabled="disableImagesBtn || getIsLoadingInRepImage"
      >
        <v-icon size="16">{{ 'mdi-image-multiple-outline' }}</v-icon>
        <span>{{ $t('slideDetails.imageTitle') }}</span>
      </v-btn>
      <v-btn
        v-if="showReplaceIcon"
        :class="`iconsBtn  ${
          getReplacementViewSelected === 'iconsView' ? 'btnActive' : ''
        } ${disableIconsBtn || getIsLoadingInRepIcon ? 'btnDisabled' : ''}`"
        :ripple="false"
        @click="selectView('iconsView')"
        :disabled="disableIconsBtn || getIsLoadingInRepIcon"
      >
        <img
          v-if="!disableIconsBtn && !getIsLoadingInRepIcon"
          :src="'/assets/img/prezentations/replace-icons.svg'"
          alt=""
        />
        <img
          v-else
          :src="'/assets/img/prezentations/replace-icons-disabled.svg'"
          alt=""
        />
        <span>{{ $t('slideDetails.iconTitle') }}</span>
      </v-btn>
    </div>

    <div class="replacementComps">
      <SlideImageReplacement
        v-if="getReplacementViewSelected === 'imagesView'"
        :selectedSlide="result"
      />

      <SlideIconReplacement
        v-else-if="getReplacementViewSelected === 'iconsView'"
        :selectedSlide="result"
      />

      <div class="noView" v-else>
        <img
          :src="'/assets/img/generate/slide-with-no-visuals.svg'"
          alt="image"
          contain
        />
        <div class="desc">
          {{ $t('slideDetails.noReplacementFlowAvailable') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import {
  doesPrefsObjectHasImage,
  doesPrefsObjectHasIcon,
} from '../../../utils';
import SlideImageReplacement from '../../SlideImageReplacement/SlideImageReplacementV2.vue';
import SlideIconReplacement from '../../SlideIconReplacement/SlideIconReplacementV2.vue';

export default {
  name: 'SlideReplaceVisuals',
  data() {
    return {};
  },
  components: {
    SlideImageReplacement,
    SlideIconReplacement,
  },
  props: {
    slideType: {
      type: String,
      default: '',
    },
    result: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('users', [
      'allowReplaceImageOverall',
      'allowReplaceImageTabs',
      'allowReplaceIconTabs',
    ]),
    ...mapGetters('slidesStore', [
      'getIsIconNotFound',
      'getIsImageNotFound',
      'getReplacementViewSelected',
      'getIsLoadingInRepImage',
      'getIsLoadingInRepIcon',
    ]),

    disableImagesBtn() {
      return !this.canReplaceImage || this.getIsImageNotFound;
    },

    disableIconsBtn() {
      return !this.canReplaceIcons || this.getIsIconNotFound;
    },

    canReplaceImage() {
      return doesPrefsObjectHasImage(this.result.prefs);
    },

    hasIconBus() {
      if (this.result.prefs && this.result.prefs.has_icons_bus) {
        if (typeof this.result.prefs.has_icons_bus === 'string') {
          return this.result.prefs.has_icons_bus === 'yes';
        }
        return this.result.prefs.has_icons_bus;
      }
      return false;
    },

    hasIcons() {
      if (this.result.prefs && this.result.prefs.has_icons) {
        if (typeof this.result.prefs.has_icons === 'string') {
          return this.result.prefs.has_icons === 'yes';
        }
        return this.result.prefs.has_icons;
      }
      return false;
    },

    hasIconAuto() {
      if (this.result.prefs && this.result.prefs.has_icon_auto) {
        if (typeof this.result.prefs.has_icon_auto === 'string') {
          return this.result.prefs.has_icon_auto === 'yes';
        }
        return this.result.prefs.has_icon_auto;
      }
      return false;
    },

    canReplaceIcons() {
      return doesPrefsObjectHasIcon(this.result.prefs);
      // return (
      //   (this.slideType === 'prezentSourcedSlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus) ||
      //   (this.slideType === 'companyBrandedSlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus) ||
      //   (this.slideType === 'userGeneratedSlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus) ||
      //   (this.slideType === 'userUploadedSlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus) ||
      //   (this.slideType === 'userRedesignSlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus) ||
      //   (this.slideType === 'userComplySlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus) ||
      //   (this.slideType === 'synthesisSlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus)
      // );
    },

    showReplaceIcon() {
      return (
        !this.hideReplaceIcon &&
        this.allowReplaceIconOverall &&
        this.allowReplaceIconTabs
      );
    },

    hideReplaceIcon() {
      return (
        ([
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
          'synthesisSlide',
        ].includes(this.slideType) &&
          this.result.isOwner === false &&
          this.result.permission === 'Viewer') ||
        this.result.permission === 'Restricted'
      );
    },

    allowReplaceIconOverall() {
      const repIconOverallFeature = this.currentUser?.features?.find(
        (ft) => ft.feature_name === 'replace_icon_overall',
      );
      if (repIconOverallFeature) {
        return repIconOverallFeature?.enabled;
      }
      return false;
    },

    showReplaceImage() {
      return (
        !this.hideReplaceImage &&
        this.allowReplaceImageOverall &&
        this.allowReplaceImageTabs
      );
    },

    hideReplaceImage() {
      return (
        ([
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
          'synthesisSlide',
        ].includes(this.slideType) &&
          this.result.isOwner === false &&
          this.result.permission === 'Viewer') ||
        this.result.permission === 'Restricted'
      );
    },
  },
  methods: {
    ...mapActions('slidesStore', [
      'setFlowActive',
      'setReplacementViewSelected',
      'setToggleBestPracticesSwitch',
    ]),

    selectView(btn) {
      this.setReplacementViewSelected(btn);
      this.setFlowActive(
        btn === 'imagesView' ? 'replaceImage' : 'replaceIcons',
      );
    },
  },
  mounted() {
    // assign view on load
    if (this.showReplaceImage && this.showReplaceIcon) {
      if (!this.disableImagesBtn) {
        this.setReplacementViewSelected('imagesView');
        this.setFlowActive('replaceImage');
      } else if (!this.disableIconsBtn) {
        this.setReplacementViewSelected('iconsView');
        this.setFlowActive('replaceIcons');
      }
    } else if (this.showReplaceImage) {
      if (!this.disableImagesBtn) {
        this.setReplacementViewSelected('imagesView');
        this.setFlowActive('replaceImage');
      }
    } else if (this.showReplaceIcon) {
      if (!this.disableIconsBtn) {
        this.setReplacementViewSelected('iconsView');
        this.setFlowActive('replaceIcons');
      }
    }

    this.setToggleBestPracticesSwitch(false);
  },
  destroyed() {},
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.slideReplaceVisualsContainer {
  .compHeader {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  .compSubHeader {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #697077;
    margin-top: 4px;
  }

  .switchButtonsContainer {
    padding: 20px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;
    .imagesBtn,
    .iconsBtn {
      min-width: 95px;
      text-transform: none;
      letter-spacing: normal;
      box-shadow: none;
      border-radius: 8px;
      background-color: rgb(255 255 255);
      color: rgb(52 58 62);
      border: 1px solid rgb(52 58 62);
      &:before {
        background-color: rgb(255 255 255);
      }
    }
    .imagesBtn {
      .v-icon {
        margin: 0px 2px 0px 0px;
      }
    }
    .iconsBtn {
      img {
        width: 16px;
        height: 16px;
        margin: 0px 2px 0px 0px;
      }
    }
    .btnActive {
      border: none;
      background-color: rgb(224 246 251);
    }
    .btnDisabled {
      border: none;
    }
  }

  .replacementComps {
    ::v-deep .container {
      .replace-image,
      .replace-icon {
        .header {
          span {
            font-weight: 500 !important;
            font-size: 16px !important;
            padding: 0;
          }
        }
        .select-image,
        .select-icon {
          margin: 0 !important;
          padding: 16px 0px 36px 0px;
        }
      }
      .image-library-wrapper {
        .adobe-library-wrapper,
        .brand-images-library-wrapper {
          height: calc(100vh - 425px) !important;
          .container {
            height: calc(100vh - 425px) !important;
            .images {
              overflow: auto !important;
              max-height: calc(100vh - 500px) !important;
              height: calc(100vh - 500px) !important;
            }
            .image-placeholder {
              overflow: auto !important;
              max-height: calc(100vh - 500px) !important;
              height: calc(100vh - 500px) !important;
            }
          }
        }
        .image-upload__container {
          overflow: auto;
          .container {
            height: calc(100vh - 475px) !important;
            padding: 36px 8px 8px 8px;
            width: 80%;
          }
        }
      }
      .icon-library-wrapper {
        .flat-icons-library-wrapper {
          height: calc(100vh - 425px) !important;
          .container {
            height: calc(100vh - 425px) !important;
            .icons {
              overflow: auto !important;
              max-height: calc(100vh - 500px) !important;
              height: calc(100vh - 500px) !important;
            }
            .image-placeholder {
              overflow: auto !important;
              max-height: calc(100vh - 500px) !important;
              height: calc(100vh - 500px) !important;
            }
          }
        }
        .brand-icons-library-wrapper {
          height: calc(100vh - 425px) !important;
          .container {
            height: calc(100vh - 425px) !important;
            .icons {
              overflow: auto !important;
              max-height: calc(100vh - 500px) !important;
              height: calc(100vh - 500px) !important;
            }
          }
        }
        .image-upload__container {
          overflow: auto;
          .container {
            height: calc(100vh - 475px) !important;
            padding: 36px 8px 8px 8px;
            width: 80%;
          }
        }
      }
    }
    .noView {
      text-align: center;
      font-size: 16px;
      margin: 40px 0px 0px 20px;
      .desc {
        margin: 20px;
        padding: 20px 10px;
      }
    }
  }
}
</style>
