var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slider_content_wrapper",attrs:{"data-pendo":_vm.id === 0 ? 'slides-card' : null}},[_c('v-card',{staticClass:"mx-auto card-wrapper",staticStyle:{"{\n        color":"white"}},[_c('div',{key:_vm.iconsKey,staticClass:"icons-wrapper"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isTrending)?_c('v-img',_vm._g(_vm._b({attrs:{"src":"/assets/img/trending.svg","alt":"","width":"34px","height":"34px"}},'v-img',attrs,false),on)):_vm._e()]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('bestPracticesGuide.knowSlideCard.trending'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.slideDownloaded === 'regDownload')?_c('v-img',_vm._g(_vm._b({attrs:{"src":"/assets/img/already-downloaded.svg","alt":"","width":"34px","height":"34px"}},'v-img',attrs,false),on)):_vm._e()]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.downloaded'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
              _vm.slideDownloaded === 'imageReplaced' ||
              _vm.slideDownloaded === 'iconReplaced'
            )?_c('v-img',_vm._g(_vm._b({attrs:{"src":"/assets/img/already-downloaded-image-replaced.svg","alt":"","width":"34px","height":"34px"}},'v-img',attrs,false),on)):_vm._e()]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.downloaded'))+" ")])])],1),_c('div',{on:{"click":function($event){return _vm.handleClickDetail(_vm.item, _vm.id, 'thumbnail')}}},[_c('v-img',{attrs:{"contain":"","src":_vm.itemData.thumbnail || '/assets/img/slides/placeholder-slide.svg',"loading":"lazy","lazy-src":"/assets/img/slides/placeholder-slide.svg","alt":"","aspect-ratio":16 / 9}})],1),_c('div',{staticClass:"prezInfoAndActions"},[_c('div',{staticClass:"nameAndActions"},[(
            _vm.slideType === 'prezentSourcedSlide' ||
            _vm.slideType === 'companyBrandedSlide'
          )?_c('div',{staticClass:"name",style:({ width: (_vm.slideNameWidth + "%"), maxWidth: "53%" }),on:{"click":function($event){return _vm.handleClickDetail(_vm.item, _vm.id, 'thumbnail')}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.item.title))+" ")]),(_vm.item.prefs && _vm.item.prefs.source === 'prezent')?_c('img',{staticStyle:{"margin":"2px 0px 0px 0px"},attrs:{"src":"/assets/img/prezent_avatar.svg","alt":"","width":"22px"}}):_vm._e()]}}],null,false,1388543670)},[_c('span',[_vm._v(_vm._s(_vm.capitalizeFirstLetter(_vm.item.title)))])])],1):_c('div',{staticClass:"nameForUploadedAndGenerated",style:({ width: (_vm.slideNameWidth + "%"), maxWidth: "53%" })},[(!_vm.isEditingName)?_c('div',{staticClass:"uploadedOrGenName",on:{"click":function($event){return _vm.handleClickDetail(_vm.item, _vm.id, 'thumbnail')}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.itemData.asset && _vm.itemData.asset.name ? _vm.itemData.asset.name : '')+" ")])]}}],null,false,3485873388)},[_c('span',[_vm._v(" "+_vm._s(_vm.itemData.asset && _vm.itemData.asset.name ? _vm.itemData.asset.name : '')+" ")])])],1):_vm._e(),(_vm.isEditingName)?_c('div',{staticClass:"card-edit-name"},[(
                _vm.itemData.asset.name.length < 5 ||
                _vm.itemData.asset.name.length > 100
              )?_c('div',{staticClass:"errorText"},[_vm._v(" "+_vm._s(("" + (_vm.itemData.asset.name.length > 5 ? '' : _vm.$t('slides.enterAtleastFiveCharacters'))))+" "+_vm._s(("" + (_vm.itemData.asset.name.length > 100 ? _vm.$t('slides.cannotExceed') : '')))+" ")]):_vm._e(),_c('v-text-field',{attrs:{"hide-details":"","label":_vm.itemData.asset.name,"solo":"","autofocus":""},on:{"focus":function($event){return _vm.handleEditNameFocus()},"blur":function($event){return _vm.handleEditNameBlur()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"color":"white","rounded":"","min-width":"25","height":"40","loading":_vm.updatingSlideData,"disabled":_vm.itemData.asset.name === '' ||
                    _vm.itemData.asset.name.length < 5 ||
                    _vm.itemData.asset.name.length > 100 ||
                    _vm.itemData.asset.name === _vm.cardNameBeforeUpdate},on:{"click":_vm.handleEditName}},[_c('v-icon',[_vm._v("mdi-check")])],1)]},proxy:true}],null,false,2235656142),model:{value:(_vm.itemData.asset.name),callback:function ($$v) {_vm.$set(_vm.itemData.asset, "name", $$v)},expression:"itemData.asset.name"}})],1):_vm._e()]),_c('div',{staticClass:"actions"},[[_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [(!_vm.isFavorite)?_c('div',[(_vm.isSlideAddingToFav)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":20,"color":"primary"}}):_c('v-icon',_vm._g(_vm._b({staticStyle:{"margin":"1px 0px 0px 8px"},attrs:{"color":"primary","size":22},on:{"click":function($event){return _vm.handleFavorite(_vm.itemData, _vm.slideOrigin)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-heart-outline ")])],1):_c('div',[(_vm.isSlideRemovingFromFav)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":20,"color":"primary"}}):_c('v-icon',_vm._g(_vm._b({staticStyle:{"margin":"1px 0px 0px 8px"},attrs:{"color":"primary","size":22},on:{"click":function($event){return _vm.handleFavorite(_vm.itemData, _vm.slideOrigin)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-heart ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(("" + (_vm.isFavorite ? _vm.$t('bestPracticesGuide.knowSlideCard.removeFavorites') : _vm.$t('bestPracticesGuide.knowSlideCard.addFavorites')))))])])],_c('div',{staticClass:"show-detail pl-2",on:{"mouseenter":_vm.trackCollectionMaxLimitEvent,"click":function($event){return _vm.handleCollection(_vm.item)}}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [(_vm.isAddedToCollection)?_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(_vm.removingFromCollection)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":20,"color":"primary"}}):_c('v-icon',{attrs:{"color":"primary","size":21}},[_vm._v(" mdi-file-minus ")])],1):_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(_vm.addingToCollection)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":20,"color":"primary"}}):_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.isCollectionLimitReached ? 'grey' : 'primary',"size":21}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-plus-outline ")])],1),(_vm.id === 0)?_c('NewFeatureBadge',{attrs:{"module-name":"collections","feature-id":'collections_thumbnail_id',"offsetX":'26',"offsetY":'-16',"size":'small'}}):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(("" + (!_vm.isAddedToCollection ? _vm.isCollectionLimitReached ? _vm.$t('replaceImage.addToCollectionTooltip') : _vm.$t('replaceImage.addToCollection') : _vm.$t('slides.removeFromCollection')))))])])],1),(_vm.isLocalDownloading)?_c('v-progress-circular',{style:({ margin: '3px 0px 0px 6px' }),attrs:{"indeterminate":"","size":20,"color":"primary"}}):_c('Download',{staticClass:"pl-1",attrs:{"mode":'icon',"file-name":_vm.getFilename(_vm.item),"item":_vm.item,"log-download":_vm.logDownload,"count-first-time":1,"on-download":function () { return _vm.handleDownloadFromListFunction(
                  false,
                  _vm.item,
                  false,
                  _vm.slideOrigin,
                  true
                ); },"origin":"slide","limitsKey":"slide","speedDialPosTop":'-140',"speedDialPosRight":'0',"data-pendo-id":_vm.id === 0 ? 'slides-download-action' : null}}),_c('v-menu',{key:_vm.menuKey,attrs:{"top":"","absolute":"","offset-y":"","close-on-click":true,"close-on-content-click":_vm.closeEllipsisMenu,"position-x":_vm.menuPos.x,"position-y":_vm.menuPos.y},scopedSlots:_vm._u([{key:"activator",fn:function(menu){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltip){return [_c('div',{staticStyle:{"position":"relative"}},[_c('v-icon',_vm._g({attrs:{"color":"primary","disabled":_vm.disableEllipsisOnThumbnail}},Object.assign({}, menu.on, tooltip.on)),[_vm._v(" mdi-dots-vertical ")]),(_vm.id === 0)?_c('NewFeatureBadge',{staticStyle:{"position":"absolute","top":"2px","right":"26px"},attrs:{"module-name":"slides","feature-id":'slides_revamp_id',"offsetX":'0',"offsetY":'0',"size":'small'}}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('slides.moreActions')))])])]}}])},[_c('v-list',_vm._l((_vm.getActionsWithPermissions()),function(action,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.handleDotOptionClick(action.name)}}},[_c('v-list-item-title',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(action.name === 'Copy link')?_c('div',_vm._g({staticClass:"copyLink"},on),[_c('div',{staticClass:"copyIconAndTitle"},[_c('div',{staticClass:"icon"},[_c('v-icon',[_vm._v(_vm._s(action.icon))])],1),_c('div',{staticClass:"actionName"},[_vm._v(" "+_vm._s(_vm.$t(action.display_name))+" ")])]),(_vm.showCopied === true)?_c('div',{staticClass:"copiedStatus"},[_c('v-icon',{attrs:{"small":"","color":"#21a7e0"}},[_vm._v("mdi-check")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('navbarActions.shareFreeTrial.copied'))+" ")])],1):_vm._e()]):_c('div',_vm._g({staticClass:"otherActions"},on),[_c('div',{staticClass:"icon"},[_c('v-icon',[_vm._v(_vm._s(action.icon))])],1),_c('div',{staticClass:"actionName"},[_vm._v(" "+_vm._s(_vm.$t(action.display_name))+" ")])])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(action.name === 'Delete' ? _vm.$t('build.step3.deleteSlide') : _vm.$t(action.display_name))+" ")])])],1)],1)}),1)],1)],2)]),_c('div',{staticClass:"typeAndDate",on:{"click":function($event){return _vm.handleClickDetail(_vm.item, _vm.id, 'thumbnail')}}},[_c('div',{staticClass:"type"},[_vm._v(" "+_vm._s(_vm.getViewsCount)+" ")]),(_vm.slideType !== 'prezentSourcedSlide')?[_c('div',[_vm._v("  |  ")]),(_vm.slideType === 'companyBrandedSlide')?_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.$t('slides.uploadedOnLabel', { formattedDate: _vm.formatCompanyBrandSlideDate( _vm.item.prefs ? _vm.item.prefs.created_at : '' ), }))+" ")]):(
              [
                'synthesisSlide',
                'userUploadedSlide',
                'userGeneratedSlide',
                'userRedesignSlide',
                'userComplySlide' ].includes(_vm.slideType)
            )?_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(((_vm.$t(_vm.getDateText)) + " " + (_vm.formatDate( _vm.item.prefs ? _vm.item.prefs.created_at : '' ))))+" ")]):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"authorAndAudience",on:{"click":function($event){return _vm.handleClickDetail(_vm.item, _vm.id, 'thumbnail')}}},[_c('div',{staticClass:"author"},[_vm._v(" "+_vm._s(_vm.$t('prezentationsList.sourceLabel', { sourceName: _vm.getSourceForTile(), }))+" ")]),(_vm.slideType !== 'prezentSourcedSlide')?[_c('div',[_vm._v("  |  ")]),(_vm.slideType === 'companyBrandedSlide')?_c('div',{staticClass:"audience"},[_vm._v(" "+_vm._s(_vm.$t('slides.author'))+" ")]):(
              [
                'synthesisSlide',
                'userUploadedSlide',
                'userGeneratedSlide',
                'userRedesignSlide',
                'userComplySlide' ].includes(_vm.slideType)
            )?_c('div',{staticClass:"audience"},[_vm._v(" "+_vm._s(_vm.$t('prezentationsList.authorLabel', { authorName: _vm.item.isOwner ? _vm.$t('fingerprint.compareFingAud.me') : _vm.item.ownerName, }))+" ")]):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"permissionAndComments"},[_c('div',{staticClass:"permission"},[(
              _vm.itemData &&
              _vm.itemData.asset &&
              Object.keys(_vm.itemData.asset).length > 0
            )?_c('SlideShareStatus',{attrs:{"itemData":_vm.itemData.asset,"type":'status',"slideType":_vm.slideType,"updateSlideWithStatus":_vm.updateSlideShareStatus}}):_vm._e()],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }