<template>
  <div class="slideDetailViewWrapper">
    <div class="progressContainer" v-if="loading">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <div class="slideView" v-else-if="!apiError">
      <div class="detailView" v-if="!isDifferentCompanySlide">
        <SlideDetailWrapper
          :favorite-slides="getFavoriteSlides"
          :handleFavorite="handleFavorite"
          :handleUpdateActiveSlide="handleUpdateActiveSlide"
          :audience="getCurrentAudience"
          :handleDownload="handleDownloadFromList"
          :logDownload="logDownload.bind(this, { fromExpandedView: true })"
          :savedQueryPayload="getSavedQueryPayload"
          :selected-slide="getActiveSlide"
          :slideViewOrigin="getSlideViewOrigin"
          :slideType="slideType"
          :processingFavSlide="processingFavSlide"
          :downloadingSlide="isLocalDownloading"
          :relatedWord="relatedWord"
        />
      </div>
      <div class="noAccess" v-else-if="isDifferentCompanySlide">
        <div class="imageContainer">
          <img src="/assets/img/slide-from-other-company.svg" alt />
        </div>
        <div class="messageContainer">
          {{ $t('slideDetails.doNotHaveAccessToContentOfOtherCompanies') }}
        </div>
        <div class="goToSlidesBtn" @click="handleGoToSlides">
          <v-btn>{{ $t('slideDetails.goToSlides') }}</v-btn>
        </div>
      </div>
    </div>
    <div class="slideLoadError" v-else-if="apiError">
      <div class="imageContainer">
        <img src="/assets/img/prezentations/prez_does_not_exist.svg" alt />
      </div>
      <div class="messageContainer">
        {{ $t(errorMsg) }}
      </div>
      <div class="goToSlidesBtn" @click="handleGoToSlides">
        <v-btn>{{ $t('slideDetails.goToSlides') }}</v-btn>
      </div>
    </div>

    <v-overlay
      class="overlay"
      :absolute="true"
      :light="true"
      z-index="20"
      :value="getShowLoadingOverlay"
      color="#fff"
      opacity="0.4"
    ></v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import EventBus from '../../common/event-bus';
// import { getFileURL } from '@/utils/calyrex';
// import { downloadFileWithAPI } from '@/utils/common';
// import SlideDetailWrapper from './SlideDetailWrapper.vue';
import SlideDetailWrapper from './SlideDetailWrapperV2.vue';
// import { getFileURLDownload } from '@/utils/calyrex-test';
import {
  getSlideDetail,
  createFavorite,
  deleteFavorite,
  createDownload,
  getSlideDataForDetailView,
  getBestPracticeSlideDetail,
  updateCountForSlideView,
  getCollection,
  getBestPractices,
  updateKnowSlideViewCount,
  createKnowFavorite,
  deleteKnowFavorite,
  createKnowDownload,
  getKnowSlideMetaData,
  getSiblings,
} from '@/utils/api-helper';
import usersApi from '../../../API/users-api';
import { trackSearchEvents } from '../../common/Analytics/SearchEvents';
import { trackSlideEvents } from '../../common/Analytics/SlideEvents.js';
import {
  TD_EXPANDED_VIEW,
  TD_QUERY,
  TD_PLACEMENT,
  TD_HYBRIDCHIP,
  TD_HYBRID_RESULTS_SECTION_CLICK,
  TD_HYBRID_RESULTS_SECTION_SLIDES,
  TD_ISDOWNLOAD,
  TD_DOWNLOADTYPE,
  TD_DOWNLOADTYPE_GS,
  TD_DOWNLOADTYPE_DIRECT,
  TD_ISFAVORITED,
  TD_REST_OF_THE_SLIDES_IN_UI,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_DOWNLOAD_AS_PDF,
  TD_YES,
  TD_NO,
  TD_AUD,
} from '../../common/Analytics/MatomoTrackingDataHelper';
// import { getLoggedInUserToken } from '../../../utils/api';
// import { callMergeSlidesApi } from '../../../utils/merge-slide-helper';

export default {
  name: 'SlideDetailView',
  components: {
    SlideDetailWrapper,
  },

  computed: {
    ...mapGetters('slidesStore', [
      'getFavoriteSlides',
      'getCurrentAudience',
      'getSavedQueryPayload',
      'getActiveSlide',
      'getCurrentThemeCode',
      'getSlideViewOrigin',
      'getSlidesSnapshot',
      'getDownloadedSlides',
      'getShowLoadingOverlay',
      'getIsSlideDetailsDirty',
      'getIsSlideACLDirty',
      'getIsSlideBestPractisesDirty',
    ]),

    ...mapGetters('replaceImageStore', ['getIsDirty']),
    ...mapGetters('replaceIconStore', ['getIsDirtyIcon']),

    ...mapState('slidesCollection', [
      'downloadSet',
      'downloadStatus',
      'nodeCountChanged',
    ]),
    ...mapState('users', ['currentUser', 'startLoadingSlidesFlag', 'themes']),
    ...mapState('slidesStore', ['slidesLoadedInUI']),

    ...mapGetters('commonDownloads', ['getDownloadLoaders']),

    isBPLSlideView() {
      return this.$route.path.includes('best-practice-library');
    },

    getLimitsKey() {
      return this.isBPLSlideView ? 'bpe-slide' : 'slide';
    },

    slideType() {
      let type = '';
      if (this.getActiveSlide && Object.keys(this.getActiveSlide).length > 0) {
        const { source } = this.getActiveSlide.prefs;
        if (source === 'prezent') {
          type = 'prezentSourcedSlide';
        } else if (source === 'generated') {
          type = 'userGeneratedSlide';
        } else if (source === 'uploaded') {
          type = 'userUploadedSlide';
        } else if (source === 'synthesis') {
          type = 'synthesisSlide';
        } else if (source === 'redesign') {
          type = 'userRedesignSlide';
        } else if (source === 'comply') {
          type = 'userComplySlide';
        } else {
          type = 'companyBrandedSlide';
        }
      }
      return type;
    },

    isLocalDownloading() {
      const ldr = this.getDownloadLoaders.find(
        (ld) => ld.loaderId === this.$route?.params?.id,
      );
      return ldr && ldr.downloading;
    },
  },
  data() {
    return {
      loading: false,
      isDifferentCompanySlide: false,
      processingFavSlide: false,
      downloadingSlide: false,
      slideMetaData: {},
      apiError: false,
      errorMsg: '',
      relatedWord: '',
    };
  },
  watch: {
    $route: {
      async handler(newVal, oldVal) {
        if (
          newVal &&
          oldVal &&
          this.$route.params.id &&
          ((newVal?.name === 'slide-detail' &&
            oldVal?.name === 'know.detail') ||
            (newVal?.name === 'slide-detail' &&
              oldVal?.name === 'slide-detail' &&
              newVal?.fullPath !== oldVal?.fullPath))
        ) {
          EventBus.$emit('MINIMIZE_LEFTNAV');
          await this.getSlideDetailsOnMount();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions('slidesCollection', [
      'setCollectionData',
      'setDownloadStatus',
      'setDownloadSet',
      'setTailorID',
      'setNodeCountChange',
    ]),

    ...mapActions('slidesStore', [
      'setActiveSlide',
      'addToFavoriteSlides',
      'removeFromFavoriteSlides',
      'addToImageReplacedSlides',
      'addToIconReplacedSlides',
      'addToDownloadedSlides',
      'saveSlidesStateSnapshot',
      'setBestPractisesOnSlide',
      'setFlowActive',
      'setSlideViewDialogContentOnLoad',
      'resetContentNotFoundFlags',
      'setReplaceImageActionClicked',
      'setToggleBestPracticesSwitch',
      'setRouteToNavigate',
      'setSlideViewConfirmationModalState',
    ]),

    ...mapActions('users', ['setCurrentUser', 'setSlidesAudienceSelected']),

    ...mapActions('commonDownloads', [
      'setDownloadItems',
      'setShowDownloadSnackbar',
      'setDownloadLoaders',
      'setAddedToLibOrFavSnackbar',
      'setNavFromAddToLibOrFavSnackbar',
    ]),

    async getSlideDetailsOnMount() {
      const assetId = this.$route?.params?.id;
      this.loading = true;
      await this.getSlideDetailAPI(assetId)
        .then(async (resp) => {
          if (this.slideBelongingToCurrentUserCompany(resp.data)) {
            const activeSlide = {
              ...resp.data,
              name: resp.data.name || resp.data.title,
              pptFile: resp.data.filename,
              file: resp.data.filename,
            };
            this.setActiveSlide(activeSlide);
            if (this.isBPLSlideView) {
              this.getBestPracticesCordinates(activeSlide);
              await this.getRelatedWord();
              this.incrementBPLViewCount();
            } else {
              this.setBestPractisesOnSlide(
                resp.data.bestPractices
                  ? resp.data.bestPractices.map((item) => ({
                      ...item,
                      isAdded: true,
                      isNew: false,
                    }))
                  : [],
              );
            }
            if (!this.isBPLSlideView) {
              const payload = {
                themeID: this.getCurrentThemeCode,
                tailorID: this.getCurrentAudience?.id
                  ? this.getCurrentAudience?.id
                  : this.currentUser?.user?.id,
                audienceType: this.getCurrentAudience?.audienceType
                  ? this.getCurrentAudience?.audienceType
                  : '',
                assetID: resp.data.unique_id,
              };
              updateCountForSlideView(payload)
                .then((res) => {
                  if (
                    this.getSlidesSnapshot &&
                    Object.keys(this.getSlidesSnapshot).length > 0
                  ) {
                    const metaData = this.getSlidesSnapshot.slideMetaData;
                    Object.keys(metaData).every((item) => {
                      if (item === resp.data.unique_id) {
                        metaData[item].viewCount =
                          metaData[item].viewCount &&
                          metaData[item].viewCount !== 'NaN'
                            ? Number(
                                Number(metaData[item].viewCount) + 1,
                              ).toString()
                            : '1';
                        return false;
                      }
                      return true;
                    });
                    this.saveSlidesStateSnapshot({
                      ...this.getSlidesSnapshot,
                      slideMetaData: metaData,
                    });
                  }
                  console.log(res);
                })
                .catch((er) => {
                  console.log(er);
                });
            }
          } else {
            this.isDifferentCompanySlide = true;
            this.saveSlidesStateSnapshot({});
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 404) {
            this.errorMsg = 'slideDetails.slideDoesNotExist';
          } else if (err.response.status === 403) {
            this.errorMsg = 'slideDetails.doNotHaveAccessToSlide';
          } else if (err.response.status === 402) {
            this.errorMsg =
              'slideDetails.doNotHaveAccessToOtherCompaniesContent';
          }
          this.saveSlidesStateSnapshot({});
          this.apiError = true;
        });
      if (!this.apiError) {
        await this.getSlideDetails();
        if (!this.isBPLSlideView) await this.getCollectionData();
      }
      this.loading = false;
    },

    getSlideDetailAPI(assetId) {
      return this.isBPLSlideView
        ? getBestPracticeSlideDetail(assetId)
        : getSlideDataForDetailView(assetId);
    },

    getDownloadMetric(source, type) {
      if (source === 'uploaded' && type === 'Slide Shells') {
        return 'sanitizedSlidesDownloaded';
      }
      if (source === 'generated') {
        return 'generateSlidesDownloaded';
      }
      if (source === 'uploaded') {
        return 'uploadSlidesDownloaded';
      }
      if (source === 'synthesis') {
        return 'synthesizeSlidesDownloaded';
      }
      if (source === 'redesign') {
        return 'redesignSlidesDownloaded';
      }
      if (source === 'comply') {
        return 'complySlidesDownloaded';
      }
      return 'downloadUsed';
    },

    handleGoToSlides() {
      this.$router.push(`/home/slides`).catch(() => {});
    },

    async logDownload(
      fromExpandedViewObj,
      item,
      imageReplaced = false,
      isGSDownload = false,
      iconReplaced = false,
    ) {
      console.log(
        fromExpandedViewObj,
        item,
        imageReplaced,
        isGSDownload,
        iconReplaced,
      );
      console.log('item.type ===> ', item.type);
      const downloadData = {
        id: uuidv4(),
        assetID: item.unique_id,
        themeID: this.getCurrentThemeCode,
        tailorID: this.getCurrentAudience?.id
          ? this.getCurrentAudience?.id
          : this.currentUser?.user?.id,
        construct: item.prefs.construct,
        idea: item.title,
        downloadedFrom: 'Slides',
        isOverightPrez: false,
        source: item.prefs.source,
        isRestricted: item.permission === 'Restricted',
        sanitizedLevel: item.sanitizedLevel,
        acceptedRejectedCount: item.acceptedRejectedCount,
      };
      if (imageReplaced) {
        downloadData.replacedImage = true;
      }
      if (iconReplaced) {
        downloadData.replacedIcon = true;
      }
      if (this.isBPLSlideView) {
        await createKnowDownload(downloadData)
          .then(() => {
            if (imageReplaced && iconReplaced) {
              this.addToImageReplacedSlides(item.unique_id);
              this.addToIconReplacedSlides(item.unique_id);
            } else if (imageReplaced) {
              this.addToImageReplacedSlides(item.unique_id);
            } else if (iconReplaced) {
              this.addToIconReplacedSlides(item.unique_id);
            } else {
              this.addToDownloadedSlides(item.unique_id);
            }
          })
          .catch((err) => console.log(err));
      } else {
        await createDownload(downloadData)
          .then(() => {
            if (imageReplaced && iconReplaced) {
              this.addToImageReplacedSlides(item.unique_id);
              this.addToIconReplacedSlides(item.unique_id);
            } else if (imageReplaced) {
              this.addToImageReplacedSlides(item.unique_id);
            } else if (iconReplaced) {
              this.addToIconReplacedSlides(item.unique_id);
            } else {
              this.addToDownloadedSlides(item.unique_id);
            }
          })
          .catch((err) => console.log(err));
      }

      this.currentUser.user.downloadUsed++;
      const downloadMetric = this.getDownloadMetric(
        item.prefs.source,
        item.type,
      );
      const userData = {
        performIncrements: {
          [downloadMetric]: 1,
        },
      };
      if (downloadMetric === 'sanitizedSlidesDownloaded') {
        userData.performIncrements.uploadSlidesDownloaded = 1;
      }
      if (imageReplaced) userData.performIncrements.downloadWithImgReplaced = 1;
      if (iconReplaced) userData.performIncrements.downloadWithIconReplaced = 1;
      if (!this.isBPLSlideView)
        usersApi.methods
          .updateUserProfile(userData)
          .then((updatedUser) => {
            const userInfo = this.currentUser;
            userInfo.user = updatedUser;
            this.setCurrentUser(userInfo);
          })
          .catch((err) => console.log(err));
      const assetIds = [...this.slidesLoadedInUI].map((sld) => sld.unique_id);
      let beforeInteractionSlides = [];
      let afterInteractionSlides = [];
      if (assetIds.length) {
        const itemToRemove = item.asset ? item.asset.unique_id : item.unique_id;
        const itemIndexToRemove = assetIds.indexOf(itemToRemove);
        if (itemIndexToRemove !== -1) {
          beforeInteractionSlides = assetIds.slice(0, itemIndexToRemove);
          afterInteractionSlides = assetIds.slice(itemIndexToRemove + 1);
        }
      }
      const slidesLoadedInUIBefore = beforeInteractionSlides.join('&');
      const slidesLoadedInUIAfter = afterInteractionSlides.join('&');
      const slidesLoadedInUI = `Before-Interaction&${slidesLoadedInUIBefore}&After-Interaction&${slidesLoadedInUIAfter}`;
      if (
        !imageReplaced &&
        this.getSlideViewOrigin === 'hybrid_search' &&
        this.getSavedQueryPayload?.query
      ) {
        trackSearchEvents.hybridSlides(item, this.currentUser, {
          [TD_ISDOWNLOAD]: 1,
          [TD_HYBRIDCHIP]: this.savedQueryPayload?.selectedChip,
          [TD_QUERY]: this.getSavedQueryPayload?.query,
          [TD_PLACEMENT]: TD_EXPANDED_VIEW,
          [TD_DOWNLOADTYPE]: isGSDownload
            ? TD_DOWNLOADTYPE_GS
            : TD_DOWNLOADTYPE_DIRECT,
          [TD_HYBRID_RESULTS_SECTION_CLICK]: TD_HYBRID_RESULTS_SECTION_SLIDES,
          [TD_COMMON_COLUMN_NAME]: TD_REST_OF_THE_SLIDES_IN_UI,
          [TD_COMMON_COLUMN_VALUE]: slidesLoadedInUI,
        });
      }
      if (
        !this.getSavedQueryPayload?.query ||
        (this.getSlideViewOrigin === 'slidesListPage' &&
          this.getSavedQueryPayload?.query)
      ) {
        trackSlideEvents.slideThumbNailClickDownload(
          { ...item, imageReplaced },
          this.currentUser,
          {
            [TD_ISDOWNLOAD]: 1,
            [TD_AUD]: this.getCurrentAudience?.id
              ? this.getCurrentAudience?.id
              : this.currentUser?.user?.id,
            [TD_PLACEMENT]: TD_EXPANDED_VIEW,
            [TD_DOWNLOADTYPE]: isGSDownload
              ? TD_DOWNLOADTYPE_GS
              : TD_DOWNLOADTYPE_DIRECT,
            [TD_COMMON_COLUMN_NAME]: TD_DOWNLOAD_AS_PDF,
            [TD_COMMON_COLUMN_VALUE]:
              item.permission === 'Restricted' ? TD_YES : TD_NO,
          },
        );
      }
    },

    async getRelatedWord() {
      this.siblings = await getSiblings(
        this.getActiveSlide?.parent?.unique_id,
        this.getActiveSlide?.parent?.sid,
        this.currentUser,
        true,
      );
      const foundSibling = this.siblings.find(
        (e) =>
          e.asset.prefs.node.toString() ===
          this.getActiveSlide?.prefs?.node.toString(),
      );
      this.relatedWord = foundSibling?.asset?.related_words || '';
    },

    capitalizeFirstLetter(str) {
      if (!str) {
        return '';
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    getSlideBasedTemplateForDownload(code) {
      const template = this.themes.find((thm) => thm.code === code);
      if (template) {
        return template.name;
      }
      return code;
    },

    async handleDownloadFromList(_, item, fromExpandedView) {
      console.log(item, fromExpandedView);
      const filePath = item.filename ? item.filename : item.asset.filename;

      let fileName = '';
      if (item.asset) {
        fileName = `
        ${
          item.asset.prefs &&
          item.asset.prefs.source &&
          (item.asset.prefs.source === 'uploaded' ||
            item.asset.prefs.source === 'generated' ||
            item.asset.prefs.source === 'redesign' ||
            item.asset.prefs.source === 'synthesis' ||
            item.asset.prefs.source === 'comply')
            ? item.asset.name
            : `${this.capitalizeFirstLetter(
                item.asset.title || item.asset.categories[0],
              )} - ${this.getSlideBasedTemplateForDownload(
                item.asset.prefs.theme,
              )}`
        }`;
      } else {
        fileName = `${
          item.prefs &&
          item.prefs.source &&
          (item.prefs.source === 'uploaded' ||
            item.prefs.source === 'generated' ||
            item.prefs.source === 'redesign' ||
            item.prefs.source === 'synthesis' ||
            item.prefs.source === 'comply')
            ? item.name
            : `${this.capitalizeFirstLetter(
                item.title || item.categories[0],
              )} - ${this.getSlideBasedTemplateForDownload(item.prefs.theme)}`
        }`;
      }

      try {
        if (fromExpandedView) {
          if (this.nodeCountChanged) {
            this.setNodeCountChange(false);
          }
        }
      } catch (e) {
        console.log(e);
      }

      // downloading slides with common download snackbar
      this.setShowDownloadSnackbar(true);
      this.setDownloadLoaders({
        loaderId: this.$route?.params?.id,
        downloading: true,
      });
      this.setDownloadItems({
        status: 'assembling',
        itemIndex: uuidv4(),
        originType: this.isBPLSlideView ? 'bpeSlideDetail' : 'slideLibDetail',
        logDownloadCallback: async () => {
          await this.logDownload(null, item);
        },
        mergeSlidesCallback: () => {},
        downloadStatusCallback: () => {
          this.setDownloadLoaders({
            loaderId: this.$route?.params?.id,
            downloading: false,
          });
        },
        metaData: {
          item: { ...item },
          filePath,
          fileName,
          limitsKey: this.getLimitsKey,
          origin: this.getLimitsKey,
        },
      });

      // const filePath = item.filename ? item.filename : item.asset.filename;
      // this.downloadingSlide = true;
      // await this.logDownload(null, item);
      // try {
      //   if (fromExpandedView) {
      //     if (this.nodeCountChanged) {
      //       this.setNodeCountChange(false);
      //     }
      //   }
      // } catch (e) {
      //   console.log(e);
      // }

      // let fileUrl = '';
      // let fileType = 'normal';
      // if (item.prefs.source === 'uploaded') {
      //   fileUrl = await getFileURL(
      //     this.currentUser.user.id,
      //     filePath,
      //     this.currentUser.userIp,
      //     'IOCUpload',
      //   );
      // } else if (
      //   item.prefs.source === 'generated' ||
      //   item.prefs.source === 'redesign' ||
      //   item.prefs.source === 'synthesis'
      // ) {
      //   fileType = 'asset';
      //   if (
      //     filePath.startsWith('/newImageReplaceOutput/output/') ||
      //     filePath.startsWith('/output/pptx/')
      //   ) {
      //     fileUrl = await getFileURLDownload(
      //       this.currentUser.user.id,
      //       filePath,
      //       this.currentUser.userIp,
      //     );
      //   } else {
      //     fileUrl = await getFileURL(
      //       this.currentUser.user.id,
      //       filePath,
      //       this.currentUser.userIp,
      //       'IOCUpload',
      //     );
      //   }
      // } else if (filePath.indexOf('/comply/') !== -1) {
      //   const awsAssetsDistribution =
      //     process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
      //   const token = await getLoggedInUserToken();
      //   fileUrl = `${awsAssetsDistribution}${
      //     filePath.startsWith('/') ? '' : '/'
      //   }${filePath}?accesskey=${token}`;
      // } else {
      //   fileUrl = await getFileURL(
      //     this.currentUser.user.id,
      //     filePath,
      //     this.currentUser.userIp,
      //   );
      // }

      // let fileName = '';
      // if (item.asset) {
      //   fileName = `
      //   ${
      //     item.asset.prefs &&
      //     item.asset.prefs.source &&
      //     (item.asset.prefs.source === 'uploaded' ||
      //       item.asset.prefs.source === 'generated' ||
      //       item.asset.prefs.source === 'redesign' ||
      //       item.asset.prefs.source === 'synthesis' ||
      //       item.asset.prefs.source === 'comply')
      //       ? item.asset.name
      //       : `${item.asset.categories[0]}_${item.asset.prefs.theme}`
      //   }`;
      // } else {
      //   fileName = `${
      //     item.prefs &&
      //     item.prefs.source &&
      //     (item.prefs.source === 'uploaded' ||
      //       item.prefs.source === 'generated' ||
      //       item.prefs.source === 'redesign' ||
      //       item.prefs.source === 'synthesis' ||
      //       item.prefs.source === 'comply')
      //       ? item.name
      //       : `${item.categories[0]}_${item.prefs.theme}`
      //   }`;
      // }

      // // downloadFileWithCustomName(fileUrl, fileName);
      // console.log(fileUrl, fileName);
      // const isRestricted =
      //   item?.prefs?.isRestricted ||
      //   item?.prefs?.permission_level === 0 ||
      //   item?.permission === 'Restricted';
      // let mergePath;
      // if (isRestricted) {
      //   const s = {
      //     outputFormat: 'pdf',
      //     outputBucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
      //     slides: [
      //       {
      //         slideId:
      //           item.filename.charAt(0) === '/'
      //             ? item.filename.substring(1)
      //             : item.filename,
      //         isReplaced:
      //           item.filename.startsWith('/newImageReplaceOutput/output/') ||
      //           item.filename.startsWith('/output/pptx/'),
      //         isUploaded: item.prefs.source === 'uploaded',
      //         isGenerated: item.prefs.source === 'generated',
      //         isSynthesis: item.prefs.source === 'synthesis',
      //         isRedesign: item.prefs.source === 'redesign',
      //         isComply: item.prefs.source === 'comply',
      //       },
      //     ],
      //   };
      //   await callMergeSlidesApi(s)
      //     .then((res) => {
      //       const mergedslide = res.data;
      //       // if (mergedslide.includes('.pptx')) {
      //       mergePath = `${mergedslide}`;
      //       // }
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       this.downloadingSlide = false;
      //     });
      // }

      // await downloadFileWithAPI(
      //   fileType,
      //   isRestricted ? mergePath : filePath,
      //   `${fileName}${isRestricted ? '.pdf' : '.pptx'}`,
      //   item?.prefs?.source,
      // );
      // this.downloadingSlide = false;
      // return true;
    },

    handleUpdateActiveSlide(selected) {
      this.setActiveSlide(selected.asset);
    },

    incrementBPLViewCount() {
      updateKnowSlideViewCount({
        assetID: this.$route.params.id,
      })
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log('error while updating the know view count', err);
        });
    },

    getBestPracticesCordinates(slide) {
      const slideData = slide || this.slideDetail;
      const formattedSlideID = this.formatNumberAs4Digits(slideData.prefs?.sid);
      getBestPractices(
        formattedSlideID,
        slideData && slideData.categories ? slideData.categories[0] : '',
      )
        .then((res) => {
          console.log(res);
          if (res.status === 'success') {
            const bestPracticeCoordinates = res.data.tips || [];
            this.setBestPractisesOnSlide(
              res.data.tips
                ? res.data.tips.map((item) => ({
                    ...item,
                    isAdded: true,
                    isNew: false,
                  }))
                : [],
            );
            if (bestPracticeCoordinates.length) {
              this.setToggleBestPracticesSwitch(true);
            }
          }
        })
        .catch((err) => console.log(err));
    },

    formatNumberAs4Digits(number) {
      if (number) {
        const numberString = number.toString();
        const formattedNumber = numberString.padStart(4, '0');
        return formattedNumber;
      }
      return 0;
    },

    assignRoutesWhenDirty(itemType) {
      if (itemType === 'slidesLib') {
        this.setNavFromAddToLibOrFavSnackbar({
          navigated: true,
          type: 'addToFavSlides',
          origin: 'fav_slides',
        });
        this.setRouteToNavigate({
          content: '/home/slides',
          type: 'snck_to_slides_page',
          action: 'push',
          pushAction: true,
        });
      } else if (itemType === 'slidesBpLib') {
        this.setNavFromAddToLibOrFavSnackbar({
          navigated: true,
          type: 'addToFavSlidesBp',
          origin: 'fav_slides_bp',
        });
        this.setRouteToNavigate({
          content: '/home/best-practice-library',
          type: 'snck_to_slides_bp_page',
          action: 'push',
          pushAction: true,
        });
      }
    },

    makeNavFromFavSnackbar(itemType) {
      if (
        (this.getIsDirty === true ||
          this.getIsDirtyIcon === true ||
          this.getIsSlideDetailsDirty === true ||
          this.getIsSlideACLDirty === true ||
          this.getIsSlideBestPractisesDirty === true) &&
        (this.$route.name === 'slide-detail' || this.isBPLSlideView) &&
        this.startLoadingSlidesFlag &&
        this.themes &&
        this.themes.length > 0
      ) {
        // showing confirmation popup on download item click when in slide detail, some flow is dirty
        this.setSlideViewConfirmationModalState(true);
        this.assignRoutesWhenDirty(itemType);
      } else {
        this.saveSlidesStateSnapshot({});
        this.setRouteToNavigate({
          content: '',
          type: '',
          action: '',
          pushAction: false,
        });

        // navigating to respective landing pages when no flow under slide detail is dirty
        if (itemType === 'slidesLib') {
          this.goToFav();
        } else if (itemType === 'slidesBpLib') {
          this.goToFavBpLib();
        }
      }
    },

    goToFav() {
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: true,
        type: 'addToFavSlides',
        origin: 'fav_slides',
      });
      this.$router.push('/home/slides').catch(() => {});
    },

    goToFavBpLib() {
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: true,
        type: 'addToFavSlidesBp',
        origin: 'fav_slides_bp',
      });
      this.$router.push('/home/best-practice-library').catch(() => {});
    },

    async handleFavorite(item) {
      if (this.isBPLSlideView) {
        const favorite = [...this.getFavoriteSlides].includes(
          item.asset.unique_id,
        );
        if (favorite || item.isFavourite) {
          this.processingFavSlide = true;
          await deleteKnowFavorite(item.asset.unique_id)
            .then((res) => {
              console.log(res);
              this.removeFromFavoriteSlides(item.asset.unique_id);
            })
            .catch((err) => console.log(err));
          this.processingFavSlide = false;
        } else {
          const favoriteData = {
            assetID: item.asset.unique_id,
            themeID: item.asset.prefs.theme,
          };
          this.processingFavSlide = true;
          await createKnowFavorite(favoriteData)
            .then((res) => {
              console.log(res);
              this.addToFavoriteSlides(item.asset.unique_id);

              this.setAddedToLibOrFavSnackbar({
                show: true,
                ctaText: 'snackBarItems.goToFavorites',
                snackbarText: 'snackBarItems.slideAddedToFav',
                navFn: () => this.makeNavFromFavSnackbar('slidesBpLib'),
                snackbarType: 'goToFav',
              });
            })
            .catch((err) => console.log(err));
          this.processingFavSlide = false;
        }
      } else {
        const favorite = [...this.getFavoriteSlides].includes(
          item.asset.unique_id,
        );
        if (favorite || item.isFavorite) {
          const params = {
            assetIds: [item.asset.unique_id],
            getFavourite: true,
          };
          this.processingFavSlide = true;
          const resp = await getSlideDetail(params);
          const favSlideId = resp.data[item.asset.unique_id].favouriteId;
          await deleteFavorite(favSlideId)
            .then((res) => {
              console.log(res);
              this.removeFromFavoriteSlides(item.asset.unique_id);
            })
            .catch((err) => console.log(err));
          this.processingFavSlide = false;
        } else {
          const favoriteData = {
            id: uuidv4(),
            assetID: item.asset.unique_id,
            source: item.asset.prefs.source,
            themeID: this.getCurrentThemeCode,
            tailorID: this.getCurrentAudience?.id
              ? this.getCurrentAudience?.id
              : this.currentUser?.user?.id,
            audienceType: this.getCurrentAudience?.audienceType
              ? this.getCurrentAudience?.audienceType
              : '',
          };
          this.processingFavSlide = true;
          await createFavorite(favoriteData)
            .then((resp) => {
              console.log(resp);
              this.addToFavoriteSlides(item.asset.unique_id);

              this.setAddedToLibOrFavSnackbar({
                show: true,
                ctaText: 'snackBarItems.goToFavorites',
                snackbarText: 'snackBarItems.slideAddedToFav',
                navFn: () => this.makeNavFromFavSnackbar('slidesLib'),
                snackbarType: 'goToFav',
              });
            })
            .catch((err) => console.log(err));
          const assetIds = [...this.slidesLoadedInUI].map(
            (sld) => sld.unique_id,
          );
          let beforeInteractionSlides = [];
          let afterInteractionSlides = [];
          if (assetIds.length) {
            const itemToRemove = item.asset
              ? item.asset.unique_id
              : item.unique_id;
            const itemIndexToRemove = assetIds.indexOf(itemToRemove);
            if (itemIndexToRemove !== -1) {
              beforeInteractionSlides = assetIds.slice(0, itemIndexToRemove);
              afterInteractionSlides = assetIds.slice(itemIndexToRemove + 1);
            }
          }
          const slidesLoadedInUIBefore = beforeInteractionSlides.join('&');
          const slidesLoadedInUIAfter = afterInteractionSlides.join('&');
          const slidesLoadedInUI = `Before-Interaction&${slidesLoadedInUIBefore}&After-Interaction&${slidesLoadedInUIAfter}`;
          if (
            this.getSlideViewOrigin === 'slidesListPage' &&
            this.getSavedQueryPayload?.query
          ) {
            trackSearchEvents.searchDropdownSlideFavorited(
              item.asset,
              this.currentUser,
              {
                [TD_ISFAVORITED]: 1,
                [TD_AUD]: this.getCurrentAudience?.id
                  ? this.getCurrentAudience?.id
                  : this.currentUser?.user?.id,
                [TD_HYBRIDCHIP]: this.savedQueryPayload?.selectedChip,
                [TD_QUERY]: this.getSavedQueryPayload?.query,
                [TD_PLACEMENT]: TD_EXPANDED_VIEW,
                [TD_COMMON_COLUMN_NAME]: TD_REST_OF_THE_SLIDES_IN_UI,
                [TD_COMMON_COLUMN_VALUE]: slidesLoadedInUI,
              },
            );
          } else if (
            this.getSlideViewOrigin === 'hybrid_search' &&
            this.getSavedQueryPayload?.query
          ) {
            trackSearchEvents.searchHybridSlideFavorited(
              item.asset,
              this.currentUser,
              {
                [TD_ISFAVORITED]: 1,
                [TD_HYBRIDCHIP]: this.savedQueryPayload?.selectedChip,
                [TD_QUERY]: this.getSavedQueryPayload?.query,
                [TD_HYBRID_RESULTS_SECTION_CLICK]:
                  TD_HYBRID_RESULTS_SECTION_SLIDES,
                [TD_PLACEMENT]: TD_EXPANDED_VIEW,
                [TD_COMMON_COLUMN_NAME]: TD_REST_OF_THE_SLIDES_IN_UI,
                [TD_COMMON_COLUMN_VALUE]: slidesLoadedInUI,
              },
            );
          } else if (
            this.getSlideViewOrigin === 'slidesListPage' &&
            !this.getSavedQueryPayload?.query
          ) {
            trackSlideEvents.slidesFaviourite(item.asset, this.currentUser, {
              [TD_ISFAVORITED]: 1,
              [TD_AUD]: this.getCurrentAudience?.id
                ? this.getCurrentAudience?.id
                : this.currentUser?.user?.id,
              [TD_PLACEMENT]: TD_EXPANDED_VIEW,
              [TD_COMMON_COLUMN_NAME]: TD_REST_OF_THE_SLIDES_IN_UI,
              [TD_COMMON_COLUMN_VALUE]: slidesLoadedInUI,
            });
          }
          this.processingFavSlide = false;
        }
      }
    },

    slideBelongingToCurrentUserCompany(slide) {
      return (
        slide.companies &&
        slide.companies.length &&
        slide.companies.filter(
          (cmp) =>
            cmp.toLowerCase().trim() ===
              this.currentUser?.company?.name.toLowerCase().trim() ||
            cmp.toLowerCase().trim() === 'generic',
        ).length > 0
      );
    },

    async getCollectionData() {
      await getCollection()
        .then(async (resp) => {
          this.setCollectionData(resp.data.collections);
          // console.log(resp);
          // console.log(this.getAllCollections);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getSlideDetails() {
      if (this.isBPLSlideView) {
        const parametersMeta = {
          assetIds: [this.getActiveSlide.unique_id],
          getDownload: true,
          getFavourite: true,
          getTrending: true,
        };

        await getKnowSlideMetaData(parametersMeta)
          .then((slideData) => {
            this.slideMetaData = { ...this.slideMetaData, ...slideData.data };
            for (const key in this.slideMetaData) {
              if (
                this.slideMetaData[key].isDownloaded === true &&
                !this.getDownloadedSlides.includes(key)
              ) {
                this.addToDownloadedSlides(key);
              }
            }
            for (const key in this.slideMetaData) {
              if (
                this.slideMetaData[key].isFavourite === true &&
                !this.getFavoriteSlides.includes(key)
              ) {
                this.addToFavoriteSlides(key);
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        const parametersMeta = {
          assetIds: [this.getActiveSlide.unique_id],
          getDownload: true,
          getFavourite: true,
        };
        await getSlideDetail(parametersMeta)
          .then((slideData) => {
            this.slideMetaData = { ...this.slideMetaData, ...slideData.data };
            for (const key in this.slideMetaData) {
              if (
                this.slideMetaData[key].isDownloaded === true &&
                !this.getDownloadedSlides.includes(key)
              ) {
                this.addToDownloadedSlides(key);
              }
            }
            for (const key in this.slideMetaData) {
              if (
                this.slideMetaData[key].isFavourite === true &&
                !this.getFavoriteSlides.includes(key)
              ) {
                this.addToFavoriteSlides(key);
              }
            }
          })
          .catch((err) => console.log(err));
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (to?.path.includes('slides')) {
      next();
    } else {
      this.setSlidesAudienceSelected(null);
      next();
    }
  },

  mounted() {
    EventBus.$emit('MINIMIZE_LEFTNAV');
  },

  async beforeMount() {
    await this.getSlideDetailsOnMount();
    // const assetId = this.$route?.params?.id;
    // this.loading = true;
    // await this.getSlideDetailAPI(assetId)
    //   .then(async (resp) => {
    //     if (this.slideBelongingToCurrentUserCompany(resp.data)) {
    //       const activeSlide = {
    //         ...resp.data,
    //         name: resp.data.name || resp.data.title,
    //         pptFile: resp.data.filename,
    //         file: resp.data.filename,
    //       };
    //       this.setActiveSlide(activeSlide);
    //       if (this.isBPLSlideView) {
    //         this.getBestPracticesCordinates(activeSlide);
    //         await this.getRelatedWord();
    //         this.incrementBPLViewCount();
    //       } else {
    //         this.setBestPractisesOnSlide(
    //           resp.data.bestPractices
    //             ? resp.data.bestPractices.map((item) => ({
    //                 ...item,
    //                 isAdded: true,
    //                 isNew: false,
    //               }))
    //             : [],
    //         );
    //       }

    //       const payload = {
    //         themeID: this.getCurrentThemeCode,
    //         tailorID: this.getCurrentAudience?.id
    //           ? this.getCurrentAudience?.id
    //           : this.currentUser?.user?.id,
    //         audienceType: this.getCurrentAudience?.audienceType
    //           ? this.getCurrentAudience?.audienceType
    //           : '',
    //         assetID: resp.data.unique_id,
    //       };
    //       updateCountForSlideView(payload)
    //         .then((res) => {
    //           if (
    //             this.getSlidesSnapshot &&
    //             Object.keys(this.getSlidesSnapshot).length > 0
    //           ) {
    //             const metaData = this.getSlidesSnapshot.slideMetaData;
    //             Object.keys(metaData).every((item) => {
    //               if (item === resp.data.unique_id) {
    //                 metaData[item].viewCount =
    //                   metaData[item].viewCount &&
    //                   metaData[item].viewCount !== 'NaN'
    //                     ? Number(
    //                         Number(metaData[item].viewCount) + 1,
    //                       ).toString()
    //                     : '1';
    //                 return false;
    //               }
    //               return true;
    //             });
    //             this.saveSlidesStateSnapshot({
    //               ...this.getSlidesSnapshot,
    //               slideMetaData: metaData,
    //             });
    //           }
    //           console.log(res);
    //         })
    //         .catch((er) => {
    //           console.log(er);
    //         });
    //     } else {
    //       this.isDifferentCompanySlide = true;
    //       this.saveSlidesStateSnapshot({});
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     if (err.response.status === 404) {
    //       this.errorMsg = 'slideDetails.slideDoesNotExist';
    //     } else if (err.response.status === 403) {
    //       this.errorMsg = 'slideDetails.doNotHaveAccessToSlide';
    //     } else if (err.response.status === 402) {
    //       this.errorMsg = 'slideDetails.doNotHaveAccessToOtherCompaniesContent';
    //     }
    //     this.saveSlidesStateSnapshot({});
    //     this.apiError = true;
    //   });
    // if (!this.apiError) {
    //   await this.getSlideDetails();
    //   await this.getCollectionData();
    // }
    // this.loading = false;
    EventBus.$emit('MINIMIZE_LEFTNAV');
  },

  beforeDestroy() {
    this.setActiveSlide({});
    this.setSlideViewDialogContentOnLoad(null);
    this.resetContentNotFoundFlags();
    this.setReplaceImageActionClicked(false);

    // handling cases when bp toggle is not reset while loading different views
    this.setToggleBestPracticesSwitch(false);
  },

  destroyed() {
    this.setActiveSlide({});
    this.setSlideViewDialogContentOnLoad(null);
    this.resetContentNotFoundFlags();
    this.setReplaceImageActionClicked(false);

    // handling cases when bp toggle is not reset while loading different views
    this.setToggleBestPracticesSwitch(false);
  },
};
</script>

<style lang="scss" scoped>
.slideDetailViewWrapper {
  width: 100%;
  height: calc(100vh - 64px);
  .progressContainer {
    text-align: center;
    margin: 200px 0px 0px 0px;
  }
  .slideView {
    height: 100%;
    .detailView {
      height: 100%;
      ::v-deep .container {
        .slide-detail-wrapper {
          box-shadow: none !important;
          padding: 0px !important;
          min-width: 100% !important;
        }
        .slide-detail-inner {
          padding: 0 !important;
          margin: 0 !important;
          width: 100% !important;
          max-width: 100% !important;
        }
      }
    }
    .noAccess {
      margin: 100px 0px 0px 0px;
      .imageContainer {
        width: 50%;
        margin: 0 auto;
      }
      .messageContainer {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin: 0px 0px 36px 0px;
      }
      .goToSlidesBtn {
        display: flex;
        justify-content: center;
        .v-btn {
          box-shadow: none;
          letter-spacing: normal;
          text-transform: none;
          border-radius: 20px;
          color: #ffffff;
          background-color: #21a7e0;
        }
      }
    }
  }
  .slideLoadError {
    margin: 100px 0px 0px 0px;
    .imageContainer {
      width: 50%;
      margin: 0 auto;
    }
    .messageContainer {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      margin: 24px 0px 36px 0px;
    }
    .goToSlidesBtn {
      display: flex;
      justify-content: center;
      .v-btn {
        box-shadow: none;
        letter-spacing: normal;
        text-transform: none;
        border-radius: 20px;
        color: #ffffff;
        background-color: #21a7e0;
      }
    }
  }
}
</style>
