<template>
  <div class="slideActionsContainer">
    <div
      v-for="(action, index) in slideActions"
      :key="index"
      class="actionContainer"
    >
      <v-btn
        rounded
        color="primary"
        text
        @click="
          () => {
            setSidePanelOpen(true);
            handleBtnClick(action.id);
          }
        "
        :disabled="action.disabled"
        :class="action.id === getSidePanelAction ? 'active' : ''"
        :data-pendo-id="action.pendoId ? action.pendoId : ''"
        v-if="action.visible"
      >
        <div class="slide-action-content">
          <v-icon dark size="20" class="slide-action-icon">
            {{ action.icon }}
          </v-icon>
          <span>{{ $t(action.labelText) }}</span>
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import {
  doesPrefsObjectHasImage,
  doesPrefsObjectHasIcon,
} from '../../../utils';

export default {
  name: 'SlideActions',
  data() {
    return {};
  },
  props: {
    slideType: {
      type: String,
      default: '',
    },
    result: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('users', [
      'allowReplaceImageOverall',
      'allowReplaceImageTabs',
      'allowReplaceIconTabs',
    ]),
    ...mapGetters('slidesStore', [
      'getSidePanelAction',
      'getIsSlideDetailsDirty',
      'getIsSlideBestPractisesDirty',
    ]),
    ...mapGetters('replaceImageStore', ['getIsDirty']),
    ...mapGetters('replaceIconStore', ['getIsDirtyIcon']),

    slideActions() {
      return [
        {
          id: 'changeLayout',
          labelText: 'slideDetails.changeLayout',
          tooltipText: 'Change layout',
          icon: 'mdi-view-split-vertical',
          isCustomIcon: false,
          pendoId: 'slide-action-change-layout',
          disabled: false,
          visible: this.showChangeLayoutOption,
        },
        {
          id: 'editSlideDetails',
          labelText: 'slideDetails.editSlideDetails',
          tooltipText: 'Edit slide details',
          icon: 'mdi-tag-multiple-outline',
          isCustomIcon: false,
          disabled: false,
          pendoId: '',
          visible: !this.hideEditSlideDetailsOption,
        },
        {
          id: 'replaceVisuals',
          labelText: 'slideDetails.replaceVisuals',
          tooltipText: 'Replace visuals',
          icon: 'mdi-image-outline',
          isCustomIcon: false,
          pendoId: 'slide-action-change-visual',
          disabled: this.disableReplaceVisualsBtn,
          visible: this.showReplaceVisual,
        },
        {
          id: 'nodeCountChange',
          labelText: 'slideDetails.editNodeCount',
          tooltipText: 'Edit node count',
          icon: 'mdi-file-tree-outline',
          isCustomIcon: false,
          pendoId: 'slide-action-change-node-count',
          disabled: false,
          visible: this.showNodeCountChangeOption,
        },
        {
          id: 'editBestPractices',
          labelText: 'slideDetails.editBestPractices',
          tooltipText: 'Edit best practices',
          icon: 'mdi-lightbulb-on-outline',
          isCustomIcon: false,
          pendoId: '',
          disabled: false,
          visible: !this.hideEditBestPracticesOption,
        },
      ];
    },

    disableReplaceVisualsBtn() {
      return (
        (!this.canReplaceImage || this.hideReplaceImage) &&
        (!this.canReplaceIcons || this.hideReplaceIcon)
      );
    },

    canReplaceImage() {
      return doesPrefsObjectHasImage(this.result.prefs);
    },

    hasIconBus() {
      if (this.result.prefs && this.result.prefs.has_icons_bus) {
        if (typeof this.result.prefs.has_icons_bus === 'string') {
          return this.result.prefs.has_icons_bus === 'yes';
        }
        return this.result.prefs.has_icons_bus;
      }
      return false;
    },

    hasIcons() {
      if (this.result.prefs && this.result.prefs.has_icons) {
        if (typeof this.result.prefs.has_icons === 'string') {
          return this.result.prefs.has_icons === 'yes';
        }
        return this.result.prefs.has_icons;
      }
      return false;
    },

    hasIconAuto() {
      if (this.result.prefs && this.result.prefs.has_icon_auto) {
        if (typeof this.result.prefs.has_icon_auto === 'string') {
          return this.result.prefs.has_icon_auto === 'yes';
        }
        return this.result.prefs.has_icon_auto;
      }
      return false;
    },

    canReplaceIcons() {
      return doesPrefsObjectHasIcon(this.result.prefs);
      // return (
      //   (this.slideType === 'prezentSourcedSlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus) ||
      //   (this.slideType === 'companyBrandedSlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus) ||
      //   (this.slideType === 'userGeneratedSlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus) ||
      //   (this.slideType === 'userUploadedSlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus) ||
      //   (this.slideType === 'userRedesignSlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus) ||
      //   (this.slideType === 'userComplySlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus) ||
      //   (this.slideType === 'synthesisSlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus)
      // );
    },

    allowReplaceIconOverall() {
      const repIconOverallFeature = this.currentUser?.features?.find(
        (ft) => ft.feature_name === 'replace_icon_overall',
      );
      return repIconOverallFeature && repIconOverallFeature.enabled;
    },

    showReplaceVisual() {
      return this.showReplaceIcon || this.showReplaceImage;
    },

    showReplaceImage() {
      return (
        !this.hideReplaceImage &&
        this.allowReplaceImageOverall &&
        this.allowReplaceImageTabs
      );
    },

    showReplaceIcon() {
      return (
        !this.hideReplaceIcon &&
        this.allowReplaceIconOverall &&
        this.allowReplaceIconTabs
      );
    },

    hideReplaceImage() {
      return (
        ([
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
          'synthesisSlide',
        ].includes(this.slideType) &&
          this.result.isOwner === false &&
          this.result.permission === 'Viewer') ||
        this.result.permission === 'Restricted'
      );
    },

    hideReplaceIcon() {
      return (
        ([
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
          'synthesisSlide',
        ].includes(this.slideType) &&
          this.result.isOwner === false &&
          this.result.permission === 'Viewer') ||
        this.result.permission === 'Restricted'
      );
    },

    hideEditBestPracticesOption() {
      return (
        this.slideType === 'prezentSourcedSlide' ||
        this.slideType === 'companyBrandedSlide' ||
        ([
          'userUploadedSlide',
          'userGeneratedSlide',
          'synthesisSlide',
          'userRedesignSlide',
          'userComplySlide',
        ].includes(this.slideType) &&
          this.result.isOwner === false &&
          this.result.permission === 'Viewer') ||
        this.result.permission === 'Restricted'
      );
    },

    hideEditSlideDetailsOption() {
      return (
        this.slideType === 'prezentSourcedSlide' ||
        this.slideType === 'companyBrandedSlide' ||
        ([
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
          'synthesisSlide',
        ].includes(this.slideType) &&
          this.result.isOwner === false &&
          this.result.permission === 'Viewer') ||
        this.result.permission === 'Restricted'
      );
    },

    showNodeCountChangeOption() {
      return ['prezentSourcedSlide', 'companyBrandedSlide'].includes(
        this.slideType,
      );
    },

    showChangeLayoutOption() {
      return ['prezentSourcedSlide', 'companyBrandedSlide'].includes(
        this.slideType,
      );
    },
  },
  methods: {
    ...mapActions('slidesStore', [
      'setSidePanelAction',
      'setSidePanelOpen',
      'setIsDetailViewInPageAction',
      'setDetailViewInPageAction',
      'setSlideViewConfirmationModalState',
    ]),

    handleBtnClick(view) {
      if (
        this.getIsDirty === true ||
        this.getIsDirtyIcon === true ||
        this.getIsSlideDetailsDirty === true ||
        this.getIsSlideBestPractisesDirty === true
      ) {
        // check for dirty flags and show popup
        this.setIsDetailViewInPageAction(true);
        this.setDetailViewInPageAction(view);
        this.setSlideViewConfirmationModalState(true);
      } else {
        this.setSidePanelAction(view);
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.slideActionsContainer {
  padding: 8px 0px;
  display: flex;
  flex-wrap: wrap;
  .actionContainer {
    .v-btn {
      text-transform: none;
      letter-spacing: normal;
      margin-right: 16px;
      .v-icon {
        padding: 4px 4px 6px 0px;
      }
      &.active::before {
        background-color: #21a7e0;
        opacity: 0.08;
      }
    }
  }
}
</style>
