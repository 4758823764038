<template>
  <div
    :class="`flat-icons-library-wrapper ${
      error.isError ? 'errorOrEmpty' : ''
    } ${isGenerate ? 'generate' : ''}`"
  >
    <v-container v-if="isLoading">
      <v-text-field
        append-icon="mdi-magnify"
        single-line
        hide-details
        :placeholder="$t('generate.searchIcons')"
        class="flat-icons-search-input"
        autocomplete="off"
      />
      <IconsPlaceHolder
        v-if="isLoading"
        :placeholderHeight="`${
          mainSlideHeight ? `${mainSlideHeight - 75}px` : ''
        }`"
      />
    </v-container>
    <v-container
      v-else
      :style="{
        height: `${mainSlideHeight ? `${mainSlideHeight}px` : ''}`,
      }"
    >
      <v-text-field
        v-if="!error.isError"
        v-model="searchKey"
        append-icon="mdi-magnify"
        single-line
        hide-details
        :placeholder="$t('generate.searchIcons')"
        class="flat-icons-search-input"
        autocomplete="off"
      />

      <div class="error-wrapper" v-if="error.isError || error.isEmpty">
        <EmptyState
          :img-url="error.imgUrl"
          :is-btn-visible="true"
          :handleCTA="loadLibraryIconsBeforeMount"
          :buttonName="$t('common.tryAgain')"
        >
          <template v-slot:description>
            <p class="error-description">{{ $t(error.message) }}</p>
          </template>
        </EmptyState>
      </div>
      <div
        v-else
        class="icons"
        @scroll="onScroll"
        ref="flatIcons"
        :style="{
          height: `${mainSlideHeight ? `${mainSlideHeight - 75}px` : ''}`,
        }"
      >
        <template>
          <div class="iconsWrapper">
            <div
              :class="`iconContainer ${
                icon.id === selectedIcon ? 'selected-icon' : ''
              } library-icon`"
              :style="{
                height: `${Number(
                  Number(mainSlideHeight / 4).toFixed(3),
                )}px !important`,
              }"
              v-for="(icon, index) in icons"
              :key="index"
              @click="onIconClick(icon)"
            >
              <img
                :id="
                  isImageReplaceWalkThrough && index === 0
                    ? 'walkthough-replace-img-id'
                    : undefined
                "
                :src="icon.thumbnails[0].url"
                lazy-src=""
                aspect-ratio="1"
                class="grey lighten-2"
              />
            </div>
          </div>
          <clip-loader
            v-if="moreIconsLoading"
            :color="`#21a7e0`"
            :width="'24'"
            :height="'24'"
            :size="'24px'"
            class="cliploader"
          />
        </template>
      </div>
    </v-container>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { flatIconsStockSearch } from '@/utils/api-helper';
import EmptyState from '../../common/EmptyState.vue';
import IconsPlaceHolder from './IconsPlaceHolder.vue';
import EventBus from '../../common/event-bus';
import {
  FLAT_ICON_SELECTED,
  BRAND_ICON_SELECTED,
  ICON_UPLOADING,
  ICON_UPLOAD_FAILED,
  LOCAL_ICON_SELECTED,
} from './replaceIconConstants';
import {
  handleDebounce,
  handleDebounceForOnScroll,
} from './slideIconReplacementUtils';
import { logSearchQuery } from '../../../utils/api-helper';
// import { TD_REPLACE_IMAGE_SOURCE_LIBRARY } from '../../common/Analytics/MatomoTrackingDataHelper';

const SOMETHING_WRONG = 'prezentationGallery.troubleAccessingIcons';
const NO_SEARCH_RESULTS = 'prezentationGallery.noResultText';
const ERROR_ICON_URL = '/assets/img/image-fetching-error.svg';
const NO_RESULTS_ICON_URL = '/assets/img/no-files-available.svg';

export default {
  name: 'FlatIconsLibrary',
  inject: {
    isImageReplaceWalkThrough: { default: false },
    query: { default: '' },
  },
  props: {
    selectedIconData: {
      type: Object,
      required: false,
    },
    isSearchActionTriggerredFromBrandIcons: {
      type: Boolean,
      default: false,
    },
    brandIconsSearchKey: {
      type: String,
      default: '',
    },
    slideData: {
      type: Object,
      required: false,
    },
    isGenerate: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EmptyState,
    IconsPlaceHolder,
    ClipLoader,
  },
  watch: {
    searchKey(key) {
      this.onSearch(key);
    },
    isSearchActionTriggerredFromBrandIcons(isSearch) {
      this.searchKey = isSearch ? this.brandIconsSearchKey : this.searchKey;
    },
  },
  async beforeMount() {
    if (!this.selectedIconData || this.isImageReplaceWalkThrough) {
      return;
    }
    await this.loadLibraryIconsBeforeMount();
    // let iconName = this.getIconName();
    // if (
    //   this.slideData?.prefs?.source === 'uploaded' ||
    //   this.slideData?.prefs?.source === 'synthesis' ||
    //   this.slideData?.prefs?.source === 'redesign' ||
    //   this.slideData?.prefs?.source === 'comply' ||
    //   this.slideData?.prefs?.source === 'generated'
    // ) {
    //   iconName =
    //     this.slideData.categories?.length > 0
    //       ? this.slideData.categories[0].replace(/_/g, ' ')
    //       : iconName;
    // }

    // if (iconName) {
    //   try {
    //     this.isLoading = true;
    //     const icons = await flatIconsStockSearch(
    //       iconName.toString(),
    //       this.limit,
    //       1,
    //     );
    //     this.icons = icons.resp.data;
    //     this.isLoading = false;
    //     this.checkDataAndSetState();
    //   } catch (e) {
    //     this.isLoading = false;
    //     console.log(e);
    //     this.setErrorObject({
    //       isError: true,
    //       message: SOMETHING_WRONG,
    //       imgUrl: ERROR_ICON_URL,
    //     });
    //   }
    // }
  },
  mounted() {
    if (this.isImageReplaceWalkThrough) {
      this.searchKey = this.query;
    }
    if (this.isSearchActionTriggerredFromBrandIcons) {
      this.searchKey = this.brandIconsSearchKey;
    }
    if (window.ResizeObserver && !this.isGenerate) {
      const mainSlideSizeObserver = new ResizeObserver((data) => {
        this.mainSlideHeight = data && data[0] && data[0].contentRect.height;
      });
      mainSlideSizeObserver.observe(
        document.getElementById('replace-main-icon'),
      );
    }
  },
  created() {
    EventBus.$on(LOCAL_ICON_SELECTED, this.emptySelectedIcon);
    EventBus.$on(BRAND_ICON_SELECTED, this.emptySelectedIcon);
  },
  beforeDestroy() {
    EventBus.$off(LOCAL_ICON_SELECTED, this.emptySelectedIcon);
    EventBus.$off(BRAND_ICON_SELECTED, this.emptySelectedIcon);
  },
  data() {
    return {
      icons: [],
      searchKey: '',
      selectedIcon: '',
      isLoading: false,
      error: {},
      page: 1,
      limit: 96,
      mainSlideHeight: 0,
      iconNameMap: {
        Dates: 'Calendar',
        Cycle: 'project life cycle',
        Pilot: 'software pilot',
        '30-60-90 Day': 'Plan',
        Title: 'success',
      },
      moreIconsLoading: false,
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    async loadLibraryIconsBeforeMount() {
      let iconName = this.getIconName();
      if (
        this.slideData?.prefs?.source === 'uploaded' ||
        this.slideData?.prefs?.source === 'synthesis' ||
        this.slideData?.prefs?.source === 'redesign' ||
        this.slideData?.prefs?.source === 'comply' ||
        this.slideData?.prefs?.source === 'generated'
      ) {
        iconName =
          this.slideData.categories?.length > 0
            ? this.slideData.categories[0].replace(/_/g, ' ')
            : iconName;
      }

      if (iconName) {
        try {
          this.isLoading = true;
          const icons = await flatIconsStockSearch(
            iconName.toString(),
            this.limit,
            1,
          );
          this.icons = icons.resp.data;
          this.isLoading = false;
          this.checkDataAndSetState();
        } catch (e) {
          this.isLoading = false;
          console.log(e);
          this.setErrorObject({
            isError: true,
            message: SOMETHING_WRONG,
            imgUrl: ERROR_ICON_URL,
          });
        }
      }
    },

    async handleTryAgain() {
      await this.loadLibraryIconsBeforeMount();
    },

    emptySelectedIcon() {
      this.selectedIcon = '';
    },
    getIconName() {
      let iconName =
        this.iconNameMap[this.selectedIconData.iconName] ||
        this.selectedIconData.iconName;
      if (iconName === 'Divider') iconName = 'Division Background';
      return iconName;
    },

    onScroll(elementObject) {
      this.moreIconsLoading = true;
      handleDebounceForOnScroll(() => this.actOnScroll(elementObject));
    },

    async actOnScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 200 >= scrollHeight) {
        if (!this.error.isError) {
          try {
            this.page += 1;
            const icons = await flatIconsStockSearch(
              this.searchKey.trim() || this.getIconName(),
              this.limit,
              this.page,
            );
            this.icons = [...this.icons, ...icons.resp.data];
            this.moreIconsLoading = false;
          } catch (e) {
            console.log(e);
            this.moreIconsLoading = false;
            this.setErrorObject({
              isError: true,
              message: SOMETHING_WRONG,
              imgUrl: ERROR_ICON_URL,
            });
          }
        } else {
          console.log('something went wrong while scrolling down', this.error);
        }
      }
      this.moreIconsLoading = false;
    },
    checkDataAndSetState() {
      if (!this.icons.length) {
        this.setErrorObject({
          isEmpty: true,
          message: NO_SEARCH_RESULTS,
          imgUrl: NO_RESULTS_ICON_URL,
        });
        this.page = 0;
      } else {
        this.setErrorObject({
          isEmpty: false,
          message: '',
          imgUrl: '',
        });
      }
    },
    setErrorObject(errorObj) {
      this.error = errorObj;
    },
    async doSearch(searchTerm) {
      try {
        const icons = await flatIconsStockSearch(
          searchTerm || this.getIconName(),
          this.limit,
          1,
        );
        this.icons = icons.resp.data;
        this.checkDataAndSetState();
        if (this.$refs.flatIcons) {
          this.$refs.flatIcons.scrollTop = 0;
        }
        if (searchTerm && searchTerm !== '*') {
          const logPayload = {
            type: 'Icon Replacement (Flat Icons)',
            searchTerm,
            searchTermResult: false,
          };
          if (this.icons.length > 0) {
            logPayload.searchTermResult = true;
          }
          logSearchQuery(logPayload)
            .then()
            .catch((err) => console.log(err));
          this.$emit('iconSearch', {
            searchTerm,
            // source: TD_REPLACE_IMAGE_SOURCE_LIBRARY,
            source: '',
            noResultsFoundValue: this.icons.length ? 0 : 1,
          });
        }
      } catch (e) {
        console.log(e);
        this.setErrorObject({
          isError: true,
          message: SOMETHING_WRONG,
          imgUrl: ERROR_ICON_URL,
        });
      }
    },
    onSearch(searchTerm) {
      handleDebounce(() => this.doSearch(searchTerm.trim()));
    },
    async onIconClick(icon) {
      this.selectedIcon = icon.id;
      EventBus.$emit(ICON_UPLOADING);
      try {
        this.$emit('iconSelected', {
          // origin: TD_REPLACE_IMAGE_SOURCE_LIBRARY,
          origin: 'flat-icons',
          source: 'flat-icons',
          id: `${icon.id}`,
          url: icon.thumbnails[0].url,
        });
        EventBus.$emit(FLAT_ICON_SELECTED);
      } catch (e) {
        console.log('uploadFlatIconsImage error: ', e);
        EventBus.$emit(ICON_UPLOAD_FAILED);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.flat-icons-library-wrapper {
  // height: 460px;
  overflow: auto;
  .flat-icons-search-input {
    background-color: #fff;
    border-radius: 24px;
    box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
    height: 40px;
    font-size: 14px;
    color: #464a4c;
    margin-bottom: 14px;
    margin-top: unset;
    padding: 0.27rem 0.7rem;
    z-index: 3;
    ::v-deep .v-input__slot::before {
      border: unset;
    }
    ::v-deep .v-text-field__slot input::placeholder {
      color: #757575;
    }
  }

  .empty-state__container {
    margin: unset;
    padding: 6rem;
  }

  .error-description {
    color: #000;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    /** trying to remove the padding as shown in the below class -> .no-prez-description but not working as expected hence negative margin here*/
    margin-top: -10px;
    text-align: center;
  }
  .no-prez-description {
    padding-top: 0px;
  }

  .icons,
  .error-wrapper {
    overflow-y: auto;

    .cliploader {
      margin-top: 10px;
    }
  }

  .icons {
    .iconsWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      padding: 4px;
      .iconContainer {
        background: #ffffff !important;
        background-color: #ffffff !important;
        box-shadow: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(33.333% - 16px);
        box-sizing: border-box;
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
          rgba(90, 125, 188, 0.05) 0px 0.25em 1em !important;
        border-radius: 8px;
        cursor: pointer;
        img {
          width: 50% !important;
          height: 50% !important;
          background: #ffffff !important;
          background-color: #ffffff !important;
          box-shadow: none !important;
        }
        &:hover {
          background: #ffffff !important;
          background-color: #ffffff !important;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
        }
      }
    }
  }

  .library-icon {
    &:hover {
      box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
        0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%) !important;
      cursor: pointer;
    }
    border-radius: 8px;
    box-shadow: $item-box-shadow;
    margin-bottom: 10px;
  }

  .selected-icon {
    border: 3px solid #21a7e0 !important;
    box-shadow: 2px 0px 4px rgba(216, 216, 216, 0.5),
      0px 2px 4px rgba(216, 216, 216, 0.5);
  }
}

.generate {
  // height: auto;
  .icons,
  .error-wrapper {
    overflow-y: auto;
    // max-height: calc(100vh - 390px);
  }
  .icons {
    .iconsWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      padding: 4px;
      .iconContainer {
        background: #ffffff !important;
        background-color: #ffffff !important;
        box-shadow: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(33.333% - 16px);
        box-sizing: border-box;
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
          rgba(90, 125, 188, 0.05) 0px 0.25em 1em !important;
        border-radius: 8px;
        cursor: pointer;
        img {
          width: 50% !important;
          height: 50% !important;
          background: #ffffff !important;
          background-color: #ffffff !important;
          box-shadow: none !important;
        }
        &:hover {
          background: #ffffff !important;
          background-color: #ffffff !important;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
        }
      }
    }
  }
}
</style>
