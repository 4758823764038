<template>
  <span>
    <div v-if="type === 'status'">
      <div
        v-if="
          slideType === 'prezentSourcedSlide' ||
          slideType === 'companyBrandedSlide'
        "
      >
        <div class="permission-status">
          <span>
            <v-icon class="permission-status-icon">
              {{ 'mdi-lock-outline' }}
            </v-icon>
            {{ $t('prezentationShareStatus.viewer') }}
          </span>
        </div>
      </div>
      <div
        v-else-if="
          slideType === 'userUploadedSlide' ||
          slideType === 'userGeneratedSlide' ||
          slideType === 'synthesisSlide' ||
          slideType === 'userComplySlide' ||
          slideType === 'userRedesignSlide'
        "
      >
        <div class="permission-status">
          <span
            v-if="
              itemData.isRestricted === true ||
              itemData.permission_level === 0 ||
              itemData.permission === 'Restricted'
            "
          >
            <v-icon class="permission-status-icon">
              {{ 'mdi-lock-outline' }}
            </v-icon>
            {{ $t('prezentationShareStatus.restricted') }}
          </span>
          <span
            v-else-if="itemData.isOwner && itemData.isShared === true"
            class="prez-status"
            @click="openSlideSharePopup"
          >
            <v-icon class="status-icon">
              {{ 'mdi-account-check-outline' }}
            </v-icon>
            {{ $t('prezentationShareStatus.shared') }}
          </span>
          <span
            v-else-if="itemData.isOwner && itemData.isShared === false"
            class="prez-status"
            @click="openSlideSharePopup"
          >
            <v-icon class="status-icon">
              {{ 'mdi-account-check-outline' }}
            </v-icon>
            {{ $t('prezentationShareStatus.private') }}
          </span>
          <span v-else-if="!itemData.isOwner">
            <v-icon class="permission-status-icon">
              {{ getSlidesIcons() }}
            </v-icon>
            {{ `${itemData.permission}` }}
          </span>
        </div>
      </div>
    </div>
  </span>
</template>
<script>
import { mapState } from 'vuex';
import ShareSlidePermissions from './ShareSlidePermissions.vue';

export default {
  name: 'SlideShareStatus',
  props: {
    type: {
      type: String,
      default: 'status',
    },
    itemData: {
      type: Object,
      required: true,
    },
    slideType: {
      type: String,
      default: '',
    },
    updateSlideWithStatus: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    ...mapState('users', ['currentTheme']),
  },
  components: {},
  mounted() {},
  methods: {
    getSlidesIcons() {
      switch (this.itemData.permission) {
        case 'Viewer':
          return 'mdi-lock-outline';
        case 'Editor':
          return 'mdi-lock-open-variant-outline';
        default:
          return '';
      }
    },

    openSlideSharePopup() {
      this.$modal.show(
        ShareSlidePermissions,
        {
          slideOpened: this.itemData,
          template: this.currentTheme,
          type: 'modal',
          updateSlide: this.updateSlideWithStatus,
        },
        {
          name: 'ShareSlidePermissions',
          width: '600px',
          height: 'auto',
          styles: {
            'max-height': '95%',
            'max-width': '800px',
            overflow: 'auto',
            'border-radius': '12px',
          },
          clickToClose: false,
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';

.status-icon {
  color: $zen-blue-default;
  font-size: 14px;
  margin-bottom: 2px;
}

.prez-status {
  color: $zen-blue-default;
}

.permission-status {
  .permission-status-icon {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    margin-bottom: 2px;
  }
}
</style>
