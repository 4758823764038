<template>
  <v-card class="confirm-popup">
    <v-card-title class="v-card-title" v-if="title">
      <v-btn class="close-btn" icon @click="onSecondaryClick">
        <v-icon dark v-text="`mdi-close`" />
      </v-btn>
      <h3 class="title">{{ title }}</h3>
    </v-card-title>
    <v-card-text class="pt-4 v-card-text">
      <div :class="title === '' ? 'text' : 'texxt'">
        {{ text }}
      </div>
      <div class="btnsContainer" v-if="primaryBtnText || secondaryBtnText">
        <div class="buttons">
          <v-btn
            class="primary-btn ma-2 py-2"
            outlined
            rounded
            @click="handlePrimaryClick()"
            :disabled="isConfirming"
            v-if="primaryBtnText"
          >
            {{ $t(primaryBtnText) }}
          </v-btn>
          <v-btn
            class="secondary-btn ma-2 py-2"
            rounded
            @click="handleSecondaryClick()"
            :loading="isConfirming"
            v-if="secondaryBtnText"
          >
            {{ $t(secondaryBtnText) }}
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SlideViewConfirmationModal',
  props: {
    title: {
      type: String,
      required: false,
      default: () => '',
    },
    text: {
      type: String,
      required: false,
      default: () => 'Are you sure you want to do this ?',
    },
    primaryBtnText: {
      type: String,
      required: false,
      default: () => 'common.no',
    },
    secondaryBtnText: {
      type: String,
      required: false,
      default: () => 'common.yes',
    },
    onPrimaryClick: {
      type: Function,
      required: false,
      default: () => {
        console.log('primary clicked');
      },
    },
    onSecondaryClick: {
      type: Function,
      required: false,
      default: () => {
        console.log('secondary clicked');
      },
    },
    isConfirming: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  methods: {
    // handleClose() {
    //   this.$modal.hide('SlideViewConfirmationModal');
    // };

    handlePrimaryClick() {
      this.onPrimaryClick();
    },

    handleSecondaryClick() {
      this.onSecondaryClick();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/app.scss', '@/scss/variables.scss';

.confirm-popup {
  padding: 8px 0 4px 0;

  .v-card-title {
    padding-bottom: 0px !important;
    .close-btn {
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 1;
    }
  }

  .v-card-text {
    padding-top: 0px !important;
    .text {
      color: #000;
      font-family: 'Lato' !important;
      font-size: 16px !important;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal !important;
      line-height: normal;
      padding-bottom: 28px !important;
    }
    .texxt {
      color: #000;
      font-family: 'Lato' !important;
      font-size: 16px !important;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal !important;
      line-height: normal;
      margin-top: 10px;
      padding-bottom: 28px !important;
    }

    .btnsContainer {
      display: flex;
      justify-content: flex-end;
      .buttons {
        width: 33%;
        display: flex;
        justify-content: space-between;
        .primary-btn,
        .secondary-btn {
          font-family: 'Lato', sans-serif;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          height: 40px !important;
          line-height: normal;
          letter-spacing: normal;
          min-width: 64px;
          text-transform: none !important;
        }

        .primary-btn {
          box-shadow: none !important;
          background-color: #21a7e0;
          color: #ffffff;
        }

        .secondary-btn {
          box-shadow: none !important;
          border: 1px solid #21a7e0;
          background-color: #ffffff;
          color: #21a7e0 !important;
        }
      }
    }
  }
}
</style>
